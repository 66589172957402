import React, { useEffect, useState } from 'react'
import delivery from '../../Assests/img/delivery.png'

//API call
import axios from "axios";

//redux
import { connect } from "react-redux";
import { addDetails, updateCart } from '../../redux/Cart/cart-actions';


const ResturantHero = (props) => {

  const url = props.url;
  const details = props.details;
  const user = props.user;
  const token = props.token;

  //Flow starts here
  useEffect(() => {
    if (details === '') {
      fetchInitialData();
    }
    if (token.length > 1) {
      fetchCartDetailsLogin();
    }
  }, []);

  const fetchInitialData = async () => {
    const apiUrl = `${url}/api/businessdetails`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          "business-id": props.rest,
        },
      });
      props.addDetails(response.data.data[0]);
    } catch (error) {
      console.log('Error');
    }
  };

  const fetchCartDetailsLogin = async () => {
    const apiUrl = `${url}/api/viewcartitem`;
    try {
      const response = await axios.post(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "language-id": 1,
          "business-id": props.rest,
        },
      });
      console.log(response.data);
      props.updateCart(response.data.cart_items);
      props.updateCartCharges(response.data.other_charges);
      props.updateCartTotals(response.data.cart_total_detail);
      props.updateCartCoupons(response.data.coupon_charges);
    } catch (error) {
      console.log("Error");
    }
  }


  return (
    <>
      <div className="p-3 bg-white mt-1">
        <div className="row align-items-start justify-content-between">
          {/* Reastaurant name, stars, address, and logos options as per design */}
          {/* <div>
            {details == '' ? <></> : <>
              <h3 className="fw-bold mb-1">{details.business_name}</h3>
              <div className="fs-8 mb-2"></div></>
              }
            <p className="small text-muted">Makhan Bhog Indian Vegetarian & Vegan Restaurant, Đường Tôn Đức Thắng, Bến Nghé, District 1, Ho Chi Minh City, Vietnam</p>
            <div className="d-flex align-items-center gap-2">
                            <div><img src={delivery} alt="" className="img-fluid ch-20"/></div>
                            <a href="support.html" className="text-decoration-none text-muted small">know more</a>
                        </div>
          </div> */}
          {/* <div className="col-3">
            <div className="shadow rounded-3">
              <div className="bg-success rounded-top text-white">
                <div className="d-flex justify-content-center gap-1 p-1">
                  <div className="h2 m-0">4.2</div>
                  <div className="pt-1"><i className="fa-solid fa-star"></i></div>
                </div>
              </div>
              <div className="text-center rounded-bottom p-1">
                <div className="mb-0 text-warning fw-bold">3,946</div>
                <div className="small text-muted">Reviews</div>
              </div>
            </div>
            <div className="badge bg-light text-dark my-2 p-2 w-100 border text-center"><i className="fa-solid fa-stopwatch text-success me-1"></i>&nbsp;30 mins
                        </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addDetails: (dets) => dispatch(addDetails(dets)),
    updateCart: (cart) => dispatch(updateCart(cart)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    user: state.shop.user,
    details: state.shop.details,
    rest: state.shop.rest,
    url: state.shop.url,
    token: state.shop.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResturantHero);