import React from 'react'
import Address from '../Component/Address/Address'
const AddressPage = () => {
    return (
        <>
            <Address/>
        </>
    )
}

export default AddressPage