import React from 'react'
import RestaurantNav from './RestaurantNav'
import ResturantHero from './ResturantHero'
import ResturantTabs from './RestaurantTabsGuest'

const Restaurant = () => {
    return (
        <>
            <RestaurantNav />
            <ResturantHero/>
            <ResturantTabs/>

        </>
    )
}

export default Restaurant