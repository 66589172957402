import React from 'react'
import { Link } from 'react-router-dom'

const CartNav = () => {
   
    return (
        <>
            <div className="bg-white shadow-sm p-3 sticky-top">
                <div className="d-flex align-items-center">
                    <div className="gap-3 d-flex align-items-center">
                    <Link to={'/homepage'}>
                            <i className="bi bi-arrow-left d-flex text-danger h2 m-0 back-page"></i></Link>
                        <h3 className="fw-bold m-0">Cart</h3>
                    </div>
                    <div className="ms-auto gap-3 d-flex align-items-center">
                        <a href="#coupon" className="btn btn-white border text-muted btn-sm rounded"><i className="fa-solid fa-tag"></i>&nbsp;Coupons</a>
                       
                    </div>
                </div>
            </div>


             
        </>
    )
}

export default CartNav