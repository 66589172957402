import React from 'react'
import Restaurant from '../Component/Restaurant/Restaurant'

const RestuarantPage = () => {
    return (
        <>
            <Restaurant/>
        </>
    )
}

export default RestuarantPage