import React from 'react'
import OrderDetail from '../Component/OrderDetails/OrderDetail'

const OrderDetailPage = () => {
    return (
        <>
            <OrderDetail/>
        </>
    )
}

export default OrderDetailPage