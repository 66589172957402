/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import veg from "../../Assests/img/veg.jpg";
import nonveg from "../../Assests/img/non.jpg";

import LoadingBar from "react-top-loading-bar";

//Material UI
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

//API call
import axios from "axios";

import { Modal } from "react-bootstrap";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

//redux
import { connect } from "react-redux";
import {
  addGuest,
  addToken,
  updateCart,
  updateCartCharges,
  updateCartCoupons,
  updateCartTotals,
  updateLocalCart,
} from "../../redux/Cart/cart-actions";

const ResturantTabs = (props) => {
  const localCart = props.localCart;
  const [progress, setProgress] = useState(0);
  const cart = props.cart;
  const token = props.token;
  const user = props.user;

  const [isLoading, setIsLoading] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [modal1, setModal1] = useState(false);
  const [modal1Local, setModal1Local] = useState(false);
  const [menuItem, setMenuItem] = useState("");

  const [addonsAdditionModal, setAddonsAdditionModal] = useState(false);
  const [addonsAdditionModalLocal, setAddonsAdditionModalLocal] =
    useState(false);
  const [prodPriceFooter, setProdPriceFooter] = useState("");

  const det = props.details;

  const setAddonsAdditionModalClose = () => {
    setAddonInfo([]);
    setAddonsAdditionModal(false);
  };

  const setAddonsAdditionModalCloseLocal = () => {
    setAddonInfo([]);
    setAddonsAdditionModalLocal(false);
  };

  const setAddonsAdditionModalOpen = () => {
    setModalClose(false);
    setAddonsAdditionModal(true);
  };

  const setAddonsAdditionModalOpenLocal = () => {
    setModalCloseLocal(false);
    setAddonsAdditionModalLocal(true);
  };

  const [foodType, setFoodType] = useState(["Veg", "Non Veg", "Vegan"]);
  const [vegChecked, setVegChecked] = useState(false);
  const [veganChecked, setVeganChecked] = useState(false);
  const [nonvegChecked, setNonvegChecked] = useState(false);

  const [tempItem, setTempItem] = useState([]);

  const handleClose = () => {
    setIsLoading(false);
  };

  const [categoryId, setCategoryId] = useState("0");

  const [categories, setCategories] = useState([]);

  const [menu, setMenu] = useState("");

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const url = props.url;

  //Flow starts here
  useEffect(() => {
    fetchInitialData();
    fetchGuestLoginDetails();
  }, []);

  //On Change of category ID
  useEffect(() => {
    fetchMenuData();
  }, [categoryId]);

  const [searchLoading, setSearchLoading] = useState(false);

  //On Change of searchText
  useEffect(() => {
    if (searchText.length > 3) {
      fetchMenuSearchedData();
    } else {
      fetchMenuData();
    }
  }, [searchText]);

  //On Change of foodtype
  useEffect(() => {
    if (vegChecked && veganChecked && nonvegChecked) {
      setFoodType(["Veg", "Non Veg", "Vegan"]);
      filterFoodType(["Veg", "Non Veg", "Vegan"]);
    } else if (vegChecked && veganChecked && !nonvegChecked) {
      setFoodType(["Veg", "Vegan"]);
      filterFoodType(["Veg", "Vegan"]);
    } else if (!vegChecked && veganChecked && nonvegChecked) {
      setFoodType(["Non Veg", "Vegan"]);
      filterFoodType(["Non Veg", "Vegan"]);
    } else if (!vegChecked && veganChecked && !nonvegChecked) {
      setFoodType(["Vegan"]);
      filterFoodType(["Vegan"]);
    } else if (vegChecked && !veganChecked && !nonvegChecked) {
      setFoodType(["Veg"]);
      filterFoodType(["Veg"]);
    } else if (!vegChecked && !veganChecked && nonvegChecked) {
      setFoodType(["Non Veg"]);
      filterFoodType(["Non Veg"]);
    } else if (vegChecked && !veganChecked && nonvegChecked) {
      setFoodType(["Veg", "Non Veg"]);
      filterFoodType(["Veg", "Non Veg"]);
    } else {
      setFoodType(["Veg", "Non Veg", "Vegan"]);
      filterFoodType(["Veg", "Non Veg", "Vegan"]);
    }
  }, [vegChecked, veganChecked, nonvegChecked]);

  const setAddItem = async (val) => {
    console.log("Inside add item");
    var body = {
      menuitem: [
        {
          item_id: val.id,
          quantity: 1,
          cart_item_id: null,
          is_delete: 0,
          addons: [],
          combo_options: [],
        },
      ],
      applied_on: 2,
      api_type: 1,
    };

    const apiUrl = `${url}/api/addupdatecartitem`;
    try {
      setProgress(10);
      const response = await axios.post(apiUrl, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "language-id": 1,
          "business-id": props.rest,
        },
      });
      console.log(response.data);
      props.updateCart(response.data.cart_items);
      props.updateCartCharges(response.data.other_charges);
      props.updateCartTotals(response.data.cart_total_detail);
      props.updateCartCoupons(response.data.coupon_charges);
      setProgress(100);
    } catch (error) {
      console.log("Error");
    }
  };

  const setAddItemLocal = async (val) => {
    const ex = localCart.find((x) => x.id === val.id);

    var item = [];

    if (ex) {
      localCart.map((x) =>
        x.id === val.id
          ? item.push({
            id: x.id,
            quantity: parseInt(x.quantity) + 1,
            is_delete: 0,
            addons: [],
          })
          : item.push({
            id: x.id,
            quantity: x.quantity,
            is_delete: 0,
            addons: [],
          })
      );
      props.updateLocalCart(item);
    } else {
      item = [
        ...localCart,
        {
          id: val.id,
          quantity: 1,
          is_delete: 0,
          addons: [],
        },
      ];
      props.updateLocalCart(item);
    }
  };

  const setSubtractItemLocal = async (val) => {
    const ex = localCart.find((x) => x.id === val.id);

    var item = [];

    if (ex) {
      localCart.map((x) =>
        x.id === val.id
          ? x.quantity == 1
            ? ""
            : item.push({
              id: x.id,
              quantity: parseInt(x.quantity) - 1,
              is_delete: 0,
              addons: [],
            })
          : item.push({
            id: x.id,
            quantity: x.quantity,
            is_delete: 0,
            addons: [],
          })
      );
      props.updateLocalCart(item);
    } else {
      item = [
        ...localCart,
        {
          id: val.id,
          quantity: 1,
          is_delete: 0,
          addons: [],
        },
      ];
      props.updateLocalCart(item);
    }
  };

  const setModalOpenLocal = async (val) => {
    setMenuItem(val);
    setProdPriceFooter(val.item_price);
    await timeout(400);
    setModal1Local(true);
  };

  const setModalOpen = async (val) => {
    setMenuItem(val);
    setProdPriceFooter(val.item_price);
    await timeout(400);
    setModal1(true);
  };

  const setModalClose = async () => {
    setModal1(false);
  };

  const setModalCloseLocal = async () => {
    setModal1Local(false);
  };

  const fetchInitialData = async () => {
    const apiUrl = `${url}/api/categorylist`;
    try {
      setProgress(10);
      setIsLoading(true);
      const response = await axios.get(apiUrl, {
        headers: {
          "business-id": props.rest,
        },
      });
      setCategories(response.data.data);
      setProgress(100);
    } catch (error) {
      console.log("Error");
    }
  };

  const fetchGuestLoginDetails = async () => {
  };

  const fetchMenuData = async () => {
    const apiUrl = `${url}/api/menuitemlist`;
    try {
      setSearchLoading(true);
      setProgress(10);
      var formData = {
        category_id: categoryId == "0" ? null : categoryId,
        subcategory_id: null,
        foodtype: foodType,
        paginate: 1,
        perPage: 10000,
        sortBy: "id",
        sortOrder: "asc",
        is_complimentary: 0,
        list_type: 0,
        is_kiosk_counter_admin: 0,
      };
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "business-id": props.rest,
          "language-id": "1",
        },
      });
      setMenu(response.data.data.data);
      setIsLoading(false);
      setSearchLoading(false);
      setProgress(100);
    } catch (error) {
      setSearchLoading(false);
      console.log("Error");
    }
  };

  const filterFoodType = async (ft) => {
    const apiUrl = `${url}/api/menuitemlist`;
    try {
      setProgress(10);
      var formData = {
        category_id: categoryId == "0" ? null : categoryId,
        subcategory_id: null,
        foodtype: ft,
        paginate: 1,
        perPage: 10000,
        sortBy: "id",
        sortOrder: "asc",
        is_complimentary: 0,
        list_type: 0,
        is_kiosk_counter_admin: 0,
      };
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "business-id": props.rest,
          "language-id": "1",
        },
      });
      setMenu(response.data.data.data);
      setIsLoading(false);
      setProgress(100);
    } catch (error) {
      console.log("Error");
    }
  };

  const fetchMenuSearchedData = async () => {
    const apiUrl = `${url}/api/menuitemlist`;
    try {
      setSearchLoading(true);
      setProgress(10);
      var formData = {
        category_id: categoryId == "0" ? null : categoryId,
        subcategory_id: null,
        foodtype: foodType,
        keyword: searchText,
        paginate: 1,
        perPage: 10000,
        sortBy: "id",
        sortOrder: "asc",
        is_complimentary: 0,
        list_type: 0,
        is_kiosk_counter_admin: 0,
      };
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "business-id": props.rest,
          "language-id": "1",
        },
      });
      setMenu(response.data.data.data);
      setIsLoading(false);
      setSearchLoading(false);
      setProgress(100);
    } catch (error) {
      setSearchLoading(false);
      console.log("Error");
    }
  };

  //Decrease item quantity when addons are not present
  const reduceQuantityForCartItemNoAddon = async (item, cartItem) => {
    var body = {
      menuitem: [
        {
          item_id: cartItem.id,
          quantity: parseInt(cartItem.quantity) - 1,
          cart_item_id: cartItem.cart_item_id,
          is_delete: 0,
          addons: [],
          combo_options: [],
        },
      ],
      applied_on: 2,
      api_type: 1,
    };

    const apiUrl = `${url}/api/addupdatecartitem`;
    try {
      setProgress(10);
      const response = await axios.post(apiUrl, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "language-id": 1,
          "business-id": props.rest,
        },
      });
      console.log(response.data);
      props.updateCart(response.data.cart_items);
      props.updateCartCharges(response.data.other_charges);
      props.updateCartTotals(response.data.cart_total_detail);
      props.updateCartCoupons(response.data.coupon_charges);
      setProgress(100);
    } catch (error) {
      console.log("Error");
    }
  };

  //Increase item quantity when addons are not present
  const addQuantityForCartItemNoAddon = async (item, cartItem) => {
    var body = {
      menuitem: [
        {
          item_id: cartItem.id,
          quantity: parseInt(cartItem.quantity) + 1,
          cart_item_id: cartItem.cart_item_id,
          is_delete: 0,
          addons: [],
          combo_options: [],
        },
      ],
      applied_on: 2,
      api_type: 1,
    };

    const apiUrl = `${url}/api/addupdatecartitem`;
    try {
      setProgress(10);
      const response = await axios.post(apiUrl, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "language-id": 1,
          "business-id": props.rest,
        },
      });
      console.log(response.data);
      props.updateCart(response.data.cart_items);
      props.updateCartCharges(response.data.other_charges);
      props.updateCartTotals(response.data.cart_total_detail);
      props.updateCartCoupons(response.data.coupon_charges);
      setProgress(100);
    } catch (error) {
      console.log("Error");
    }
  };

  const addToCartItemWithAddonModal = async (item) => {
    console.log(item);
    setAddonsAdditionModalOpen();
  };

  const addToCartItemWithAddonModalLocal = async (item) => {
    console.log(item);
    setAddonsAdditionModalOpenLocal();
  };

  const [addoninfo, setAddonInfo] = useState([]);
  const handleChangeAddonAddition = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setProdPriceFooter(
        parseInt(prodPriceFooter) + parseInt(e.target.value.split(",")[2])
      );
      setAddonInfo([
        ...addoninfo,
        {
          id: parseInt(e.target.value.split(",")[0]),
          addon_name: e.target.value.split(",")[1],
          addon_price: e.target.value.split(",")[2],
          quantity: 1,
          total_addon_price: parseInt(e.target.value.split(",")[2]),
        },
      ]);
    } else {
      setProdPriceFooter(
        parseInt(prodPriceFooter) - parseInt(e.target.value.split(",")[2])
      );
      setAddonInfo(
        addoninfo.filter((x) => x.id !== parseInt(e.target.value.split(",")[0]))
      );
    }
  };

  function convertToAddonArray(arr) {
    var newvar = [];
    {
      arr.map((item) => {
        newvar.push(item.id);
      });
    }
    return newvar;
  }

  // This method checks for same addon config items and prevents from adding a duplicate item
  const addToCartItemWithAddon = async () => {
    const ex = cart.find(
      (x) =>
        x.id === menuItem.id &&
        JSON.stringify(convertToAddonArray(x.addons)) ===
        JSON.stringify(
          convertToAddonArray(addoninfo.sort((a, b) => a.id - b.id))
        )
    );

    if (ex) {
      console.log(ex);
      var body = {
        menuitem: [
          {
            item_id: ex.id,
            quantity: parseInt(ex.quantity) + 1,
            cart_item_id: ex.cart_item_id,
            is_delete: 0,
            addons: ex.addons,
            combo_options: [],
          },
        ],
        applied_on: 2,
        api_type: 1,
      };

      const apiUrl = `${url}/api/addupdatecartitem`;
      try {
        setProgress(10);
        const response = await axios.post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "language-id": 1,
            "business-id": props.rest,
          },
        });
        console.log(response.data);
        props.updateCart(response.data.cart_items);
        props.updateCartCharges(response.data.other_charges);
        props.updateCartTotals(response.data.cart_total_detail);
        props.updateCartCoupons(response.data.coupon_charges);
        setAddonsAdditionModalClose();
        setProgress(100);
      } catch (error) {
        console.log("Error");
        setAddonsAdditionModalClose();
      }
    } else {
      body = {
        menuitem: [
          {
            item_id: menuItem.id,
            quantity: 1,
            cart_item_id: null,
            is_delete: 0,
            addons: addoninfo,
            combo_options: [],
          },
        ],
        applied_on: 2,
        api_type: 1,
      };

      const apiUrl = `${url}/api/addupdatecartitem`;
      try {
        setProgress(10);
        const response = await axios.post(apiUrl, body, {
          headers: {
            Authorization: `Bearer ${token}`,
            "language-id": 1,
            "business-id": props.rest,
          },
        });
        console.log(response.data);
        props.updateCart(response.data.cart_items);
        props.updateCartCharges(response.data.other_charges);
        props.updateCartTotals(response.data.cart_total_detail);
        props.updateCartCoupons(response.data.coupon_charges);
        setAddonsAdditionModalClose();
        setProgress(100);
      } catch (error) {
        console.log("Error");
        setAddonsAdditionModalClose();
      }
    }
  };

  // LOCAL - This method checks for same addon config items and prevents from adding a duplicate item in local
  const addToCartItemWithAddonLocal = async () => {
    console.log("control is here");
    const ex = localCart.find(
      (x) =>
        x.id === menuItem.id &&
        JSON.stringify(convertToAddonArray(x.addons)) ===
        JSON.stringify(
          convertToAddonArray(addoninfo.sort((a, b) => a.id - b.id))
        )
    );

    var item = [];

    if (ex) {
      localCart.map((x) =>
        x.id === menuItem.id &&
          JSON.stringify(convertToAddonArray(x.addons)) ===
          JSON.stringify(
            convertToAddonArray(addoninfo.sort((a, b) => a.id - b.id))
          )
          ? item.push({
            id: x.id,
            quantity: parseInt(x.quantity) + 1,
            is_delete: 0,
            addons: x.addons,
          })
          : item.push({
            id: x.id,
            quantity: x.quantity,
            is_delete: 0,
            addons: x.addons,
          })
      );
      console.log(item);
      props.updateLocalCart(item);
    } else {
      item = [
        ...localCart,
        {
          id: menuItem.id,
          quantity: 1,
          is_delete: 0,
          addons: addoninfo,
        },
      ];
      console.log(item);
      props.updateLocalCart(item);
    }
    setAddonsAdditionModalCloseLocal();
  };

  const addQuantityForCartItemWithAddon = async (val) => {
    setAddSubtractModalClose();
    setMenuItem(val);
    setProdPriceFooter(val.item_price);
    await timeout(400);
    setAddonsAdditionModalOpen();
  };

  const setAddSubtractModalClose = () => {
    setAddSubtractModal(false);
  };

  const setAddSubtractModalCloseLocal = () => {
    setAddSubtractModalLocal(false);
  };

  const [addSubtractMenuItem, setAddSubtractMenuItem] = useState("");
  const [addSubtractModal, setAddSubtractModal] = useState(false);
  const [addSubtractModalLocal, setAddSubtractModalLocal] = useState(false);
  const reduceQuantityForCartItemWithAddon = async (val) => {
    setAddSubtractMenuItem(val);
    await timeout(400);
    setAddSubtractModal(true);
  };

  const reduceQuantityForCartItemWithAddonLocal = async (val) => {
    setAddSubtractMenuItem(val);
    await timeout(400);
    setAddSubtractModalLocal(true);
  };

  function getCountOfItem(itemUnderConsideration) {
    var newvar = 0;
    {
      cart.map((x) =>
        x.id == itemUnderConsideration.id
          ? (newvar = newvar + parseInt(x.quantity))
          : ""
      );
    }
    console.log(newvar);
    return newvar;
  }

  function getCountOfItemLocal(itemUnderConsideration) {
    var newvar = 0;
    {
      localCart.map((x) =>
        x.id == itemUnderConsideration.id
          ? (newvar = newvar + parseInt(x.quantity))
          : ""
      );
    }
    console.log(newvar);
    return newvar;
  }

  const subtractFromFinalPopupLocal = async (value) => {
    const ex = localCart.find(
      (x) =>
        x.id === value.id &&
        JSON.stringify(convertToAddonArray(x.addons)) ===
        JSON.stringify(
          convertToAddonArray(value.addons.sort((a, b) => a.id - b.id))
        )
    );

    var item = [];

    if (ex) {
      localCart.map((x) =>
        x.id === value.id &&
          JSON.stringify(convertToAddonArray(x.addons)) ===
          JSON.stringify(
            convertToAddonArray(value.addons.sort((a, b) => a.id - b.id))
          )
          ? x.quantity == 1
            ? ""
            : item.push({
              id: x.id,
              quantity: parseInt(x.quantity) - 1,
              is_delete: 0,
              addons: x.addons,
            })
          : item.push({
            id: x.id,
            quantity: x.quantity,
            is_delete: 0,
            addons: x.addons,
          })
      );
      console.log(item);
      props.updateLocalCart(item);
    } else {
      item = [
        ...localCart,
        {
          id: menuItem.id,
          quantity: 1,
          is_delete: 0,
          addons: addoninfo,
        },
      ];
      console.log(item);
      props.updateLocalCart(item);
    }
  };

  const subtractFromFinalPopup = async (value) => {
    const body = {
      menuitem: [
        {
          item_id: value.id,
          quantity: parseInt(value.quantity) - 1,
          cart_item_id: value.cart_item_id,
          is_delete: 0,
          addons: value.addons,
          combo_options: [],
        },
      ],
      applied_on: 2,
      api_type: 1,
    };
    const apiUrl = `${url}/api/addupdatecartitem`;
    try {
      setProgress(10);
      const response = await axios.post(apiUrl, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "language-id": 1,
          "business-id": props.rest,
        },
      });
      console.log(response.data);
      props.updateCart(response.data.cart_items);
      props.updateCartCharges(response.data.other_charges);
      props.updateCartTotals(response.data.cart_total_detail);
      props.updateCartCoupons(response.data.coupon_charges);
      setAddonsAdditionModalClose();
      setProgress(100);
    } catch (error) {
      console.log("Error");
      setAddonsAdditionModalClose();
    }
  };

  const addFromFinalPopupLocal = async (value) => {
    const ex = localCart.find(
      (x) =>
        x.id === value.id &&
        JSON.stringify(convertToAddonArray(x.addons)) ===
        JSON.stringify(
          convertToAddonArray(value.addons.sort((a, b) => a.id - b.id))
        )
    );

    var item = [];

    if (ex) {
      localCart.map((x) =>
        x.id === value.id &&
          JSON.stringify(convertToAddonArray(x.addons)) ===
          JSON.stringify(
            convertToAddonArray(value.addons.sort((a, b) => a.id - b.id))
          )
          ? item.push({
            id: x.id,
            quantity: parseInt(x.quantity) + 1,
            is_delete: 0,
            addons: x.addons,
          })
          : item.push({
            id: x.id,
            quantity: x.quantity,
            is_delete: 0,
            addons: x.addons,
          })
      );
      console.log(item);
      props.updateLocalCart(item);
    } else {
      item = [
        ...localCart,
        {
          id: menuItem.id,
          quantity: 1,
          is_delete: 0,
          addons: addoninfo,
        },
      ];
      console.log(item);
      props.updateLocalCart(item);
    }
  };

  const addFromFinalPopup = async (value) => {
    const body = {
      menuitem: [
        {
          item_id: value.id,
          quantity: parseInt(value.quantity) + 1,
          cart_item_id: value.cart_item_id,
          is_delete: 0,
          addons: value.addons,
          combo_options: [],
        },
      ],
      applied_on: 2,
      api_type: 1,
    };
    const apiUrl = `${url}/api/addupdatecartitem`;
    try {
      setProgress(10);
      const response = await axios.post(apiUrl, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "language-id": 1,
          "business-id": props.rest,
        },
      });
      console.log(response.data);
      props.updateCart(response.data.cart_items);
      props.updateCartCharges(response.data.other_charges);
      props.updateCartTotals(response.data.cart_total_detail);
      props.updateCartCoupons(response.data.coupon_charges);
      setAddonsAdditionModalClose();
      setProgress(100);
    } catch (error) {
      console.log("Error");
      setAddonsAdditionModalClose();
    }
  };

  return (
    <>
      <LoadingBar
        color="#f06c14"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {isLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <main className="sticky-top">
            <div className="bg-light">
              <div className="row m-0">
                <div className="col-12 p-0">
                  <ul
                    className="nav osahan-tab row m-0 nav-tabs-custom"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item col p-0" role="presentation">
                      <button
                        class={
                          categoryId == "0"
                            ? "nav-link bg-light active"
                            : "nav-link bg-light"
                        }
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        onClick={() => setCategoryId("0")}
                      >
                        All
                      </button>
                    </li>
                    {categories.map((x) => (
                      <li className="nav-item col p-0" role="presentation">
                        <button
                          class={
                            categoryId == x.id
                              ? "nav-link bg-light active"
                              : "nav-link bg-light"
                          }
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                          onClick={() => setCategoryId(x.id)}
                        >
                          {x.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="bg-white px-3 pt-3">
              <div className="input-group bg-white shadow rounded-md border p-1 mb-3">
                <span className="input-group-text border-0 bg-white">
                  <i className="fa-solid fa-magnifying-glass text-danger"></i>
                </span>
                <input
                  type="text"
                  className="shadow-none form-control border-0"
                  placeholder="Search within the menu.."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center gap-3">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={vegChecked}
                    onChange={() => setVegChecked(!vegChecked)}
                    role="switch"
                    id="flexSwitchCheckDefaultVeg"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefaultdd"
                  >
                    Veg
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={nonvegChecked}
                    onChange={() => setNonvegChecked(!nonvegChecked)}
                    role="switch"
                    id="flexSwitchCheckDefaultVeg"
                  />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Non-Veg
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={veganChecked}
                    onChange={() => setVeganChecked(!veganChecked)}
                    role="switch"
                    id="flexSwitchCheckDefaultVegan"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefaultdd"
                  >
                    Vegan
                  </label>
                </div>
              </div>
            </div>
          </main>

          {/* Tabs Data */}
          <div className="px-3 py-5 mb-5">
            <div className="row">
              <div className="col-12">
                <div className="tab-content" id="pills-tabContent">
                  {/* <!-- 1st tab --> */}
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div>
                      {searchLoading ? (
                        <div>
                          <SkeletonTheme baseColor="#E0E0E0" highlightColor="#9e9e9e">
                            <div className="row">
                              <div className="col-6">
                                <div className="row">
                                  <Skeleton count={1} width={170} />
                                  <Skeleton count={1} width={70} />
                                  <Skeleton count={1} width={100} />
                                  <Skeleton count={1} width={200} />
                                </div>
                              </div>
                              <div className="col-6">
                                <Skeleton count={1} height={220} />
                              </div>
                            </div>
                          </SkeletonTheme>
                        </div>
                      )
                        :
                        (
                          <>
                            {Array.isArray(menu) && menu.length > 0
                              ? menu.map((val) => {
                                return (
                                  <>
                                    <div className="store-list-2">
                                      <div className="row justify-content-between">
                                        <div className="col-6">
                                          <div className="d-flex align-items-center gap-2 mb-3">
                                            <img
                                              src={
                                                val.item_type == "Non Veg"
                                                  ? nonveg
                                                  : veg
                                              }
                                              alt=""
                                              className="img-fluid ch-20"
                                            />
                                            {val.item_calories ? (
                                              <div className="badge border border-danger text-danger">
                                                {val.item_calories} Kcals
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                          <h3
                                            className="fw-bold mb-1"
                                            data-bs-target="#exampleModal"
                                            role="button"
                                            onClick={() =>
                                              setModalOpen(val)
                                            }
                                          >
                                            {val.item_name}
                                          </h3>
                                          {val.description != null ? (
                                            <p className="small text-muted mb-3">
                                              {val.description.slice(3, 60)}...
                                            </p>
                                          ) : (
                                            <p className="small text-muted mb-3">
                                              Recommended by our chef
                                            </p>
                                          )}
                                          <h6 className="fw-bold">
                                            {det.currency_code} {val.item_price}
                                          </h6>
                                          {/* <div className="badge border border-warning">
                                            <span>
                                              <i className="fa-solid fa-star text-warning"></i>
                                            </span>
                                            <span>
                                              <i className="fa-solid fa-star text-warning"></i>
                                            </span>
                                            <span>
                                              <i className="fa-solid fa-star text-warning"></i>
                                            </span>
                                            <span>
                                              <i className="fa-solid fa-star text-warning"></i>
                                            </span>
                                            <span className="text-dark">{ }</span>
                                          </div> */}
                                        </div>
                                        <div className="col-4">
                                          <div className="border-0">
                                            <img
                                              src={
                                                url + "/storage" + val.item_image
                                              }
                                              className="card-img-top rounded-3 "
                                              alt="..."
                                              data-bs-target="#exampleModal"
                                              role="button"
                                              onClick={() =>
                                                setModalOpen(val)
                                              }
                                            />
                                            {user.phone_number ? (
                                              // Case when user is present
                                              <div className=" d-grid px-0 pt-2 pb-0">
                                                {/* Case when item is added initially */}
                                                {cart && cart.length > 0 ? (
                                                  val.addonsdetails &&
                                                    val.addonsdetails.length > 0 ? (
                                                    <>
                                                      {/* Case for when Addons are present inside an element which is added in the cart already */}
                                                      {!!cart.find(
                                                        (x) => x.id === val.id
                                                      ) == true ? (
                                                        <>
                                                          <div className="btn btn-white border border-danger px-1">
                                                            <div className="d-flex align-items-start justify-content-between px-1">
                                                              <div className="minus">
                                                                <i
                                                                  className="fa-solid fa-minus text-danger"
                                                                  onClick={() =>
                                                                    reduceQuantityForCartItemWithAddon(
                                                                      val
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                              <button
                                                                className="shadow-none form-control text-center border-0 p-0 box"
                                                                type="text"
                                                                aria-label="default input example"
                                                              >
                                                                {getCountOfItem(
                                                                  val
                                                                )}
                                                              </button>
                                                              <div className="plus">
                                                                <i
                                                                  className="fa-solid fa-plus text-danger"
                                                                  onClick={() =>
                                                                    reduceQuantityForCartItemWithAddon(
                                                                      val
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {/* Case for when Addons are present inside an element which is not present / added in the cart already */}
                                                          <button
                                                            type="button"
                                                            className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                                            data-bs-target="#exampleModal"
                                                            onClick={() =>
                                                              setModalOpen(val)
                                                            }
                                                          >
                                                            Add +
                                                          </button>
                                                        </>
                                                      )}
                                                      <div className="small text-muted align-centre-custom">
                                                        customizable
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {/* Case for when Addons are not present */}
                                                      {/* Logic of this loop : */}
                                                      {/* If item is present in cart, show quantity add subtract, else show add button; i.e. if item is present in cart == true; */}
                                                      {!!cart.find(
                                                        (x) => x.id === val.id
                                                      ) == true ? (
                                                        <>
                                                          {/* Logic of this loop : */}
                                                          {/* To check which menu item is present in cart and display that with quantity based on item id as addons is not present */}
                                                          {cart.map((x) =>
                                                            x.id == val.id ? (
                                                              <>
                                                                <div className="btn btn-white border border-danger px-1">
                                                                  <div className="d-flex align-items-start justify-content-between px-1">
                                                                    <div className="minus">
                                                                      <i
                                                                        className="fa-solid fa-minus text-danger"
                                                                        onClick={() =>
                                                                          reduceQuantityForCartItemNoAddon(
                                                                            val,
                                                                            x
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                    <button
                                                                      className="shadow-none form-control text-center border-0 p-0 box"
                                                                      type="text"
                                                                      aria-label="default input example"
                                                                    >
                                                                      {x.quantity}
                                                                    </button>
                                                                    <div className="plus">
                                                                      <i
                                                                        className="fa-solid fa-plus text-danger"
                                                                        onClick={() =>
                                                                          addQuantityForCartItemNoAddon(
                                                                            val,
                                                                            x
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <></>
                                                              // empty brackets if cart is and val id are different, as no item to be displayed
                                                            )
                                                          )}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {/* Case for when Addons are not present */}
                                                          <button
                                                            type="button"
                                                            className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                                            data-bs-target="#exampleModal"
                                                            onClick={() =>
                                                              setAddItem(val)
                                                            }
                                                          >
                                                            Add +
                                                          </button>
                                                        </>
                                                      )}
                                                    </>
                                                  )
                                                ) : // Case when items are not added initially
                                                  val.addonsdetails &&
                                                    val.addonsdetails.length > 0 ? (
                                                    <>
                                                      {/* Case for when Addons are present */}
                                                      <button
                                                        type="button"
                                                        className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                                        data-bs-target="#exampleModal"
                                                        onClick={() =>
                                                          setModalOpen(val)
                                                        }
                                                      >
                                                        Add +
                                                      </button>
                                                      <div className="small text-muted align-centre-custom">
                                                        customizable
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {/* Case for when Addons are not present */}
                                                      <button
                                                        type="button"
                                                        className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                                        data-bs-target="#exampleModal"
                                                        onClick={() =>
                                                          setAddItem(val)
                                                        }
                                                      >
                                                        Add +
                                                      </button>
                                                    </>
                                                  )}
                                              </div>
                                            ) : (
                                              // Case when user is not present
                                              // Case when user is not present
                                              // Case when user is not present
                                              // Case when user is not present
                                              <div className=" d-grid px-0 pt-2 pb-0">
                                                {/* Case when item is added initially */}
                                                {localCart &&
                                                  localCart.length > 0 ? (
                                                  val.addonsdetails &&
                                                    val.addonsdetails.length > 0 ? (
                                                    <>
                                                      {/* Case for when Addons are present inside an element which is added in the cart already */}
                                                      {!!localCart.find(
                                                        (x) => x.id === val.id
                                                      ) == true ? (
                                                        <>
                                                          <div className="btn btn-white border border-danger px-1">
                                                            <div className="d-flex align-items-start justify-content-between px-1">
                                                              <div className="minus">
                                                                <i
                                                                  className="fa-solid fa-minus text-danger"
                                                                  onClick={() =>
                                                                    reduceQuantityForCartItemWithAddonLocal(
                                                                      val
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                              <button
                                                                className="shadow-none form-control text-center border-0 p-0 box"
                                                                type="text"
                                                                aria-label="default input example"
                                                              >
                                                                {getCountOfItemLocal(
                                                                  val
                                                                )}
                                                              </button>
                                                              <div className="plus">
                                                                <i
                                                                  className="fa-solid fa-plus text-danger"
                                                                  onClick={() =>
                                                                    reduceQuantityForCartItemWithAddonLocal(
                                                                      val
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {/* Case for when Addons are present inside an element which is not present / added in the cart already */}
                                                          <button
                                                            type="button"
                                                            className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                                            data-bs-target="#exampleModal"
                                                            onClick={() =>
                                                              setModalOpenLocal(val)
                                                            }
                                                          >
                                                            Add +
                                                          </button>
                                                        </>
                                                      )}
                                                      <div className="small text-muted align-centre-custom">
                                                        customizable
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {/* Case for when Addons are not present */}
                                                      {/* Logic of this loop : */}
                                                      {/* If item is present in cart, show quantity add subtract, else show add button; i.e. if item is present in cart == true; */}
                                                      {!!localCart.find(
                                                        (x) => x.id === val.id
                                                      ) == true ? (
                                                        <>
                                                          {/* Logic of this loop : */}
                                                          {/* To check which menu item is present in cart and display that with quantity based on item id as addons is not present */}
                                                          {localCart.map((x) =>
                                                            x.id == val.id ? (
                                                              <>
                                                                <div className="btn btn-white border border-danger px-1">
                                                                  <div className="d-flex align-items-start justify-content-between px-1">
                                                                    <div className="minus">
                                                                      <i
                                                                        className="fa-solid fa-minus text-danger"
                                                                        onClick={() =>
                                                                          setSubtractItemLocal(
                                                                            val
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                    <button
                                                                      className="shadow-none form-control text-center border-0 p-0 box"
                                                                      type="text"
                                                                      aria-label="default input example"
                                                                    >
                                                                      {x.quantity}
                                                                    </button>

                                                                    <div className="plus">
                                                                      <i
                                                                        className="fa-solid fa-plus text-danger"
                                                                        onClick={() =>
                                                                          setAddItemLocal(
                                                                            val
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <></>
                                                              // empty brackets if cart is and val id are different, as no item to be displayed
                                                            )
                                                          )}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {/* Case for when Addons are not present */}
                                                          <button
                                                            type="button"
                                                            className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                                            data-bs-target="#exampleModal"
                                                            onClick={() =>
                                                              setAddItemLocal(val)
                                                            }
                                                          >
                                                            Add +
                                                          </button>
                                                        </>
                                                      )}
                                                    </>
                                                  )
                                                ) : // Case when items are not added initially
                                                  val.addonsdetails &&
                                                    val.addonsdetails.length > 0 ? (
                                                    <>
                                                      {/* Case for when Addons are present */}
                                                      <button
                                                        type="button"
                                                        className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                                        data-bs-target="#exampleModal"
                                                        onClick={() =>
                                                          setModalOpenLocal(val)
                                                        }
                                                      >
                                                        Add +
                                                      </button>
                                                      <div className="small text-muted align-centre-custom">
                                                        customizable
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {/* Case for when Addons are not present */}
                                                      <button
                                                        type="button"
                                                        className="btn btn-outline-danger fw-bold text-uppercase btn-sm rounded"
                                                        data-bs-target="#exampleModal"
                                                        onClick={() =>
                                                          setAddItemLocal(val)
                                                        }
                                                      >
                                                        Add +
                                                      </button>
                                                    </>
                                                  )}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                  </>
                                );
                              })
                              : <div className="text-center mt-4">
                                <div className="d-flex flex-column align-items-center">
                                  <img src="/img.png" className="img-fluid mb-3" alt="img" />
                                  <h2 className="text-muted h2">No items found</h2>
                                </div>
                              </div>
                            }
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Modal --> */}
          <Modal size="md" show={modal1} onHide={setModalClose}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header border-0">
                  <h5 className="modal-title">
                    {menuItem.master_category
                      ? menuItem.master_category.name
                      : ""}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => setModalClose()}
                  ></button>
                </div>
                <div className="modal-body p-0">
                  <div>
                    <img
                      src={url + "/storage" + menuItem.item_image}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="p-3">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <img
                        src={menuItem.item_type == "Non Veg" ? nonveg : veg}
                        alt=""
                        className="img-fluid ch-20"
                      />
                      {menuItem.item_calories ? (
                        <div className="badge border border-danger text-danger">
                          {menuItem.item_calories} Kcals
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <h3 className="fw-bold mb-1">{menuItem.item_name}</h3>
                    {menuItem.description != null ? (
                      <p
                        className="small text-muted mb-3"
                        dangerouslySetInnerHTML={{
                          __html: menuItem.description,
                        }}
                      ></p>
                    ) : (
                      <p className="small text-muted mb-3">
                        Recommended by our chef
                      </p>
                    )}
                    <h6 className="fw-bold">{det.currency_code} {menuItem.item_price}</h6>
                    <div className="badge border border-warning mb-2">
                      <span>
                        <i className="fa-solid fa-star text-warning"></i>
                      </span>
                      <span>
                        <i className="fa-solid fa-star text-warning"></i>
                      </span>
                      <span>
                        <i className="fa-solid fa-star text-warning"></i>
                      </span>
                      <span>
                        <i className="fa-solid fa-star text-warning"></i>
                      </span>
                    </div>
                  </div>
                </div>
                {menuItem.addonsdetails ? (
                  menuItem.addonsdetails.length >= 1 ? (
                    <div className="store-list-2 mx-4">
                      <h4 className="fw-bold ">Available Add Ons</h4>
                      {menuItem.addonsdetails.map((add) => (
                        <>
                          <hr />
                          <div className="row justify-content-between my-4">
                            <div className="col-6">
                              <h3 className="fw-bold mb-1">{add.addon_name}</h3>
                              <h6 className="small text-muted ">
                                {det.currency_code} {add.addon_price}
                              </h6>
                            </div>
                            <div className="col-4">
                              <div className="card border-0">
                                <img
                                  src={url + "/storage" + add.addon_image}
                                  className="card-img-top rounded-3"
                                  alt="..."
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {
                  (!user?.phone_number ||
                    (!props.cart?.map((x) => x.id).includes(menuItem.id) && menuItem?.addonsdetails?.length >= 1)
                  ) && <div className="modal-footer d-flex justify-content-between">
                    <btn
                      className="btn btn-danger col-12"
                      onClick={() => {
                        if (menuItem.addonsdetails.length >= 1) {
                          addToCartItemWithAddonModal(menuItem)
                        } else {
                          console.log("control is here");
                          setAddItemLocal(menuItem)
                          setModalClose()
                        }
                      }}
                    >
                      Add item
                    </btn>

                  </div>
                }
              </div>
            </div>
          </Modal>

          {/* <!-- Local Modal - Add item to cart with addons --> */}
          <Modal size="md" show={modal1Local} onHide={setModalCloseLocal}>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header border-0">
                  <h5 className="modal-title">
                    {menuItem.master_category
                      ? menuItem.master_category.name
                      : ""}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => setModalCloseLocal()}
                  ></button>
                </div>
                <div className="modal-body p-0">
                  <div>
                    <img
                      src={url + "/storage" + menuItem.item_image}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="p-3">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <img
                        src={menuItem.item_type == "Non Veg" ? nonveg : veg}
                        alt=""
                        className="img-fluid ch-20"
                      />
                      {menuItem.item_calories ? (
                        <div className="badge border border-danger text-danger">
                          {menuItem.item_calories} Kcals
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <h3 className="fw-bold mb-1">{menuItem.item_name}</h3>
                    {menuItem.description != null ? (
                      <p
                        className="small text-muted mb-3"
                        dangerouslySetInnerHTML={{
                          __html: menuItem.description,
                        }}
                      ></p>
                    ) : (
                      <p className="small text-muted mb-3">
                        Recommended by our chef
                      </p>
                    )}
                    <h6 className="fw-bold">{det.currency_code} {menuItem.item_price}</h6>
                    <div className="badge border border-warning mb-2">
                      <span>
                        <i className="fa-solid fa-star text-warning"></i>
                      </span>
                      <span>
                        <i className="fa-solid fa-star text-warning"></i>
                      </span>
                      <span>
                        <i className="fa-solid fa-star text-warning"></i>
                      </span>
                      <span>
                        <i className="fa-solid fa-star text-warning"></i>
                      </span>
                    </div>
                  </div>
                </div>
                {menuItem.addonsdetails ? (
                  menuItem.addonsdetails.length >= 1 ? (
                    <div className="store-list-2 mx-4">
                      <h4 className="fw-bold ">Available Add Ons</h4>
                      {menuItem.addonsdetails.map((add) => (
                        <>
                          <hr />
                          <div className="row justify-content-between my-4">
                            <div className="col-6">
                              <h3 className="fw-bold mb-1">{add.addon_name}</h3>
                              <h6 className="small text-muted ">
                                {det.currency_code} {add.addon_price}
                              </h6>
                            </div>
                            <div className="col-4">
                              <div className="card border-0">
                                <img
                                  src={url + "/storage" + add.addon_image}
                                  className="card-img-top rounded-3"
                                  alt="..."
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                <div className="modal-footer d-flex justify-content-between">
                  <btn
                    className="btn btn-danger col-12"
                    onClick={() => {
                      addToCartItemWithAddonModalLocal(menuItem)
                    }}
                  >
                    Add item
                  </btn>
                </div>
              </div>
            </div>
          </Modal>

          {/* <!-- Modal --> */}
          <Modal
            size="md"
            show={addonsAdditionModal}
            onHide={setAddonsAdditionModalClose}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header border-0">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <img
                      src={menuItem.item_type == "Non Veg" ? nonveg : veg}
                      alt=""
                      className="img-fluid ch-20"
                    />
                    {menuItem.item_calories ? (
                      <div className="badge border border-danger text-danger">
                        {menuItem.item_calories} Kcals
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => setAddonsAdditionModalClose()}
                  ></button>
                </div>
                <div className="modal-body p-0">
                  <div className="p-3 d-flex justify-content-between">
                    <h3 className="fw-bold ">{menuItem.item_name}</h3>
                    <h3 className="fw-bold ">{det.currency_code} {menuItem.item_price}</h3>
                  </div>
                </div>
                <hr />
                {menuItem.addonsdetails ? (
                  menuItem.addonsdetails.length >= 1 ? (
                    <div className="store-list-2 px-3">
                      <h5 className=" text-muted ">Select Add Ons</h5>
                      {menuItem.addonsdetails.map((add) => (
                        <>
                          <div className="row justify-content-between my-4">
                            <div className="col-6">
                              <h5 className="fw-bold mb-1">{add.addon_name}</h5>
                            </div>
                            <div className="col-4">
                              <h6 className="small text-muted ">
                                {det.currency_code} {add.addon_price}
                              </h6>
                            </div>
                            <div className="col-2">
                              {" "}
                              <input
                                className="mx-1"
                                name="addon"
                                value={[
                                  add.id,
                                  add.addon_name,
                                  add.addon_price,
                                ]}
                                type="checkbox"
                                onChange={(e) => {
                                  handleChangeAddonAddition(e);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <hr />

                <div className=" d-flex justify-content-between">
                  <btn
                    className="btn btn-danger col-12"
                    onClick={() => addToCartItemWithAddon()}
                  >
                    Add item - {det.currency_code} {prodPriceFooter}
                  </btn>
                </div>
              </div>
            </div>
          </Modal>

          {/* <!-- Local Modal - Adding items with addons to local cart --> */}
          <Modal
            size="md"
            show={addonsAdditionModalLocal}
            onHide={setAddonsAdditionModalCloseLocal}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header border-0">
                  <div className="d-flex align-items-center gap-2 mb-2">
                    <img
                      src={menuItem.item_type == "Non Veg" ? nonveg : veg}
                      alt=""
                      className="img-fluid ch-20"
                    />
                    {menuItem.item_calories ? (
                      <div className="badge border border-danger text-danger">
                        {menuItem.item_calories} Kcals
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => setAddonsAdditionModalCloseLocal()}
                  ></button>
                </div>
                <div className="modal-body p-0">
                  <div className="p-3 d-flex justify-content-between">
                    <h3 className="fw-bold ">{menuItem.item_name}</h3>
                    <h3 className="fw-bold ">{det.currency_code} {menuItem.item_price}</h3>
                  </div>
                </div>
                <hr />
                {menuItem.addonsdetails ? (
                  menuItem.addonsdetails.length >= 1 ? (
                    <div className="store-list-2 px-3">
                      <h5 className=" text-muted ">Select Add Ons</h5>
                      {menuItem.addonsdetails.map((add) => (
                        <>
                          <div className="row justify-content-between my-4">
                            <div className="col-6">
                              <h5 className="fw-bold mb-1">{add.addon_name}</h5>
                            </div>
                            <div className="col-4">
                              <h6 className="small text-muted ">
                                {det.currency_code} {add.addon_price}
                              </h6>
                            </div>
                            <div className="col-2">
                              {" "}
                              <input
                                className="mx-1"
                                name="addon"
                                value={[
                                  add.id,
                                  add.addon_name,
                                  add.addon_price,
                                ]}
                                type="checkbox"
                                onChange={(e) => {
                                  handleChangeAddonAddition(e);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <hr />

                <div className=" d-flex justify-content-between">
                  <btn
                    className="btn btn-danger col-12"
                    onClick={() => addToCartItemWithAddonLocal()}
                  >
                    Add item - {det.currency_code} {prodPriceFooter}
                  </btn>
                </div>
              </div>
            </div>
          </Modal>

          {/* <!-- Modal --> */}
          <Modal
            size="md"
            show={addSubtractModal}
            onHide={setAddSubtractModalClose}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={setAddSubtractModalClose}
                  ></button>
                </div>

                <div className="bg-white p-3 mb-3">
                  <div>
                    {cart.map((val, key) => {
                      return val.id == addSubtractMenuItem.id ? (
                        <>
                          <div className="mb-3 d-flex gap-2">
                            <img
                              src={
                                addSubtractMenuItem.item_type == "Non Veg"
                                  ? nonveg
                                  : veg
                              }
                              alt=""
                              className="img-fluid ch-20"
                            />
                            <div>
                              <h6 className="fw-bold mb-1">{val.item_name}</h6>
                              <p className="mb-0">
                                {det.currency_code} {val.item_total_amount_with_addon}
                              </p>
                              {val.addons.length > 0 ? (
                                <>
                                  {val.addons.map((addonItem) => (
                                    <p className="small text-muted m-0">
                                      + {addonItem.addon_name}
                                    </p>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="ms-auto text-end cw-80">
                              <div className="btn btn-white btn-sm border border-danger px-2 rounded">
                                <div className="d-flex align-items-center justify-content-between gap-2">
                                  <div className="minus">
                                    <i
                                      className="fa-solid fa-minus text-danger"
                                      onClick={() =>
                                        subtractFromFinalPopup(val)
                                      }
                                    ></i>
                                  </div>
                                  <button
                                    className="shadow-none form-control text-center border-0 p-0 box"
                                    type="text"
                                    aria-label="default input example"
                                  >
                                    {val.quantity}
                                  </button>

                                  <div className="plus">
                                    <i
                                      className="fa-solid fa-plus text-danger"
                                      onClick={() => addFromFinalPopup(val)}
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      ) : (
                        <></>
                      );
                    })}

                    <div>
                      <button
                        className="btn text-danger btn-sm"
                        onClick={() =>
                          addQuantityForCartItemWithAddon(addSubtractMenuItem)
                        }
                      >
                        Customize item
                      </button>
                    </div>
                  </div>
                </div>

                <div className=" d-flex justify-content-between">
                  <btn
                    className="btn btn-danger col-12"
                    onClick={setAddSubtractModalClose}
                  >
                    Done
                  </btn>
                </div>
              </div>
            </div>
          </Modal>

          {/* <!-- LOCAL - Modal to add subtract items with addons already in cart --> */}
          <Modal
            size="md"
            show={addSubtractModalLocal}
            onHide={setAddSubtractModalCloseLocal}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content border-0">
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={setAddSubtractModalCloseLocal}
                  ></button>
                </div>

                <div className="bg-white p-3 mb-3">
                  <div>
                    {localCart.map((val, key) => {
                      return val.id == addSubtractMenuItem.id ? (
                        <>
                          <div className="mb-3 d-flex gap-2">
                            <img
                              src={
                                addSubtractMenuItem.item_type == "Non Veg"
                                  ? nonveg
                                  : veg
                              }
                              alt=""
                              className="img-fluid ch-20"
                            />
                            <div>
                              <h6 className="fw-bold mb-1">{addSubtractMenuItem.item_name}</h6>
                              {/* <p className="mb-0">
                                {det.currency_code} {addSubtractMenuItem.item_total_amount_with_addon}
                              </p> */}
                              {val.addons.length > 0 ? (
                                <>
                                  {val.addons.map((addonItem) => (
                                    <p className="small text-muted m-0">
                                      + {addonItem.addon_name}
                                    </p>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="ms-auto text-end cw-80">
                              <div className="btn btn-white btn-sm border border-danger px-2 rounded">
                                <div className="d-flex align-items-center justify-content-between gap-2">
                                  <div className="minus">
                                    <i
                                      className="fa-solid fa-minus text-danger"
                                      onClick={() =>
                                        subtractFromFinalPopupLocal(val)
                                      }
                                    ></i>
                                  </div>
                                  <button
                                    className="shadow-none form-control text-center border-0 p-0 box"
                                    type="text"
                                    aria-label="default input example"
                                  >
                                    {val.quantity}
                                  </button>

                                  <div className="plus">
                                    <i
                                      className="fa-solid fa-plus text-danger"
                                      onClick={() =>
                                        addFromFinalPopupLocal(val)
                                      }
                                    ></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      ) : (
                        <></>
                      );
                    })}

                    <div>
                      <button
                        className="btn text-danger btn-sm"
                        onClick={() =>
                          addToCartItemWithAddonModalLocal(addSubtractMenuItem)
                        }
                      >
                        Customize item
                      </button>
                    </div>
                  </div>
                </div>

                <div className=" d-flex justify-content-between">
                  <btn
                    className="btn btn-danger col-12"
                    onClick={setAddSubtractModalCloseLocal}
                  >
                    Done
                  </btn>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (cart) => dispatch(updateCart(cart)),
    updateLocalCart: (cart) => dispatch(updateLocalCart(cart)),
    updateCartTotals: (cart) => dispatch(updateCartTotals(cart)),
    updateCartCharges: (cart) => dispatch(updateCartCharges(cart)),
    updateCartCoupons: (cart) => dispatch(updateCartCoupons(cart)),
    addGuest: (guest) => dispatch(addGuest(guest)),
    addToken: (guest) => dispatch(addToken(guest)),
  };
};

const mapStateToProps = (state) => {
  return {
    localCart: state.shop.localCart,
    cart: state.shop.cart,
    guest: state.shop.guest,
    user: state.shop.user,
    details: state.shop.details,
    rest: state.shop.rest,
    url: state.shop.url,
    token: state.shop.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResturantTabs);
