import { legacy_createStore as createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducer";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
const persistConfig = {
    key : 'persist-store',
    storage
}
const persistedReducer = persistReducer(persistConfig,rootReducer);
const store = createStore(persistedReducer, composeWithDevTools());

// const store = createStore(rootReducer, composeWithDevTools());

export const persistor = persistStore(store);
export default store;