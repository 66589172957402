import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import image from "../Assests/img/image1.jpg";
import Login from "./Login";
import { addGuest, addRestaurantID, addTable, addToken, resetStore } from "./../redux/Cart/cart-actions";
import { addRuntime } from "./../redux/Cart/cart-actions";
import toast from "react-hot-toast";

const HomePage = ({
  url,
  rest,
  addRestaurantID,
  addTable,
  addRuntime,
  addGuest,
  addToken,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const pathName = window.location.href;
  const pseudoPathName =
    "https://web-sandbox.dahlia.tech/maharajademo/#/?business_id=eyJpdiI6IlQ3a1NkUWZ3eUcweVV1ZXJHQ3lMVnc9PSIsInZhbHVlIjoiM1N5eWY1ZU1zRTJzSW01R2I1d09YUT09IiwibWFjIjoiOTQ4M2YzMzU0NDQ4OTEzZjdmNmQxNDc4YmM3YjFjOTkzNWQzZWE1MDMxYTc3YzYyYWEwY2UxNjRmODY3M2ZkZCIsInRhZyI6IiJ9&table_id=1710640550";

  const fetchGuestLoginDetails = useCallback(async () => {
    try {
      const apiUrl = `${url}/api/guestcustomerlogin`;
      const response = await axios.get(apiUrl, {
        headers: {
          "business-id": rest,
        },
      });
      addGuest(response.data.customer);
      addToken(response.data.token);
      response?.data?.status === 200 && toast.success("Logged in as Guest");
      navigate("/homepage");
      return response.data;
    } catch (error) {
      setError(error);
      console.log(" error:", error)
    }
  }, [url, rest, addGuest, addToken, navigate]);

  const fetchData = useCallback(async () => {
    try {
      const apiUrl = `${url}/api/businesstoken`;
      const formData = new FormData();
      formData.append("url", pathName);
      const response = await axios.post(apiUrl, formData);
      addRestaurantID(response.data.data.business_id);
      addTable(response.data.data.table_id);
      addRuntime("1");

      const guestLoginDetails = await fetchGuestLoginDetails();
      setIsLoading(false);
      return guestLoginDetails;
    } catch (error) {
      setError(error);
      setIsLoading(false);
      console.log(" error:", error)
    }
  }, [url, pseudoPathName, addRestaurantID, addTable, addRuntime, fetchGuestLoginDetails]);

  useEffect(() => {
    fetchGuestLoginDetails();
    phuedoAPIcallTest();
    fetchData();
  }, [fetchData]);

  const phuedoAPIcallTest = () => {

  }


  return (
    <>
      {isLoading && (
        <>
          <div>
            <img src={image} alt="" className="img-fluid landing-image-css" />
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  addRestaurantID,
  addRuntime,
  addTable,
  resetStore,
  addGuest,
  addToken,
};

const mapStateToProps = (state) => {
  return ({
    url: state.shop.url,
    rest: state.shop.rest,
  })
}


export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
