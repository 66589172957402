import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//redux
import { connect } from "react-redux";
import { Dropdown, NavDropdown } from 'react-bootstrap';
import toast from 'react-hot-toast';
import {   resetStore  } from '../../redux/Cart/cart-actions';

//API call
import axios from "axios";

import { addDetails, updateCart } from '../../redux/Cart/cart-actions';

const RestaurantNav = (props) => {

    const user = props.user;
    const cart = props.cart;
    const local = props.localCart;

    const url = props.url;
    const details = props.details;
    const token = props.token;
  
    //Flow starts here
    useEffect(() => {
      if (details === '') {
        fetchInitialData();
      }
      if (token.length > 1) {
        fetchCartDetailsLogin();
      }
    }, []);
  
    const fetchInitialData = async () => {
      const apiUrl = `${url}/api/businessdetails`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            "business-id": props.rest,
          },
        });
        props.addDetails(response.data.data[0]);
      } catch (error) {
        console.log('Error');
      }
    };
  
    const fetchCartDetailsLogin = async () => {
      const apiUrl = `${url}/api/viewcartitem`;
      try {
        const response = await axios.post(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            "language-id": 1,
            "business-id": props.rest,
          },
        });
        console.log(response.data);
        props.updateCart(response.data.cart_items);
        props.updateCartCharges(response.data.other_charges);
        props.updateCartTotals(response.data.cart_total_detail);
        props.updateCartCoupons(response.data.coupon_charges);
      } catch (error) {
        console.log("Error");
      }
    }

    const navigate = useNavigate();

    const navoptions = [
        {
            id: 0,
            title: "Homepage",
            path: "homepage",
            icon: <i className="bi bi-house me-2"></i>
        },
        {
            id: 1,
            title: "Enable Location",
            path: "",
            icon: <i className="bi bi-geo-alt me-2"></i>
        },
        {
            id: 2,
            title: "Add Location",
            path: "",
            icon: <i className="bi bi-geo me-2"></i>
        },
    ]

    return (
        <>
            <div className="bg-white shadow-sm p-3">
                <div className="d-flex align-items-center">
                    <div className="gap-3 d-flex align-items-center">
                        {/* commented back arrow and restaurant name*/}
                        {/* <a href="/homepage"><i className="bi bi-arrow-left d-flex text-danger h2 m-0 back-page"></i></a> */}
                      
                        {details == '' ? <></> : <>
              <h3 className="fw-bold mb-0">{details.business_name}</h3>
              <div className="fs-8 mb-2"></div></>
              }
                    </div>
                    <div className="ms-auto gap-3 d-flex align-items-center">
                        {/* Coupons button commented */}
                        {/* <button type="button" className="btn btn-white border btn-sm rounded-md text-muted" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                            <span><i
                                className="fa-solid fa-tag"></i></span>&nbsp;Coupon
                        </button> */}
                        <a className="text-dark m-0 font-normal" href={"#cart"}>
                            <i className="bi bi-cart4 d-flex m-0 h1">
                                {!!user.phone_number
                                    ? cart.length > 0
                                        ? <span className='m-0 ms-1'>
                                            <h6 className='text-danger' style={{ fontStyle: 'normal' }}>
                                                {cart.reduce((acc, item) => acc + Number(item.quantity), 0)}
                                            </h6>
                                        </span>
                                        : <></>
                                    : local.length > 0
                                        ? <span className='m-0 ms-1'>
                                            <h6 className='text-danger' style={{ fontStyle: 'normal' }}>
                                                {local.reduce((acc, item) => acc + Number(item.quantity), 0)}
                                            </h6>
                                        </span>
                                        : <></>
                                }
                            </i>
                        </a>

                        {/* <i className="bi bi-user-circle d-flex m-0 h1"></i> */}
                        {/* <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <i className="bi bi-person-fill h2"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}

                        {
                            !!user.phone_number && <NavDropdown
                                id="nav-dropdown-dark-example"
                                title={<i className="bi bi-person-fill h1"></i>}
                                menuVariant="light"
                            >
                                <NavDropdown.Item
                                    onClick={() => {
                                        toast.success('Logged Out Successfully')
                                        props.resetStore()                                      
                                        navigate('/')
                                    }}
                                >Logout</NavDropdown.Item>

                            </NavDropdown>
                        }



                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Coupons</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="p-3">
                                <div>
                                    <div className="mb-2">
                                        <div className="mb-3">
                                            <img src="img/p1.png" alt="" className="img-fluid ch-40 border rounded-2" /></div>
                                        <div className="h4 m-0">Get 50% OFF up to $100</div>
                                    </div>
                                    <p className="text-muted">Valid on total value of items worth $159 or more.&nbsp;<span className="text-dark">View Details</span></p>
                                    <div className="d-flex justify-content-between pb-3 align-items-center">
                                        <div className="text-uppercase fw-bold border border-danger text-danger rounded px-2 py-1 h5 m-0">maxsafety</div>
                                        <div><a href="#" data-bs-dismiss="modal" aria-label="Close" className="text-decoration-none text-danger">Apply</a></div>
                                    </div>
                                    <div className="text-primary">You will save $100 with this code</div>
                                </div>
                                <hr />
                                <div>
                                    <div className="mb-2">
                                        <div className="mb-3"><img src="img/p1.png" alt="" className="img-fluid ch-40 border rounded-2" /></div>
                                        <div className="h4 m-0">50% OFF up to $100 and $30 Paytm cashback using Paytm</div>
                                    </div>
                                    <p className="text-muted">Valid on total value of items worth $159 or more.&nbsp;<span className="text-dark">View Details</span></p>
                                    <div className="d-flex justify-content-between pb-3 align-items-center">
                                        <div className="text-uppercase fw-bold border border-danger text-danger rounded px-2 py-1 h5 m-0">zompaytm</div>
                                        <div><a href="#" data-bs-dismiss="modal" aria-label="Close" className="text-decoration-none text-danger">Apply</a></div>
                                    </div>
                                    <div className="text-primary">You will save $100 &amp; get extra cashback with this code</div>
                                </div>
                                <hr />
                                <div>
                                    <div className="mb-2">
                                        <div className="mb-3"><img src="img/p2.png" alt="" className="img-fluid ch-40 border rounded-2" /></div>
                                        <div className="h4 m-0">Get 60% OFF up to $140</div>
                                    </div>
                                    <p className="text-muted">Valid on total value of items worth $159 or more.&nbsp;<span className="text-dark">View Details</span></p>
                                    <div className="d-flex justify-content-between pb-3 align-items-center">
                                        <div className="text-uppercase fw-bold border border-danger text-danger rounded px-2 py-1 h5 m-0">simplparty</div>
                                        <div><a href="#" data-bs-dismiss="modal" aria-label="Close" className="text-decoration-none text-danger">Apply</a></div>
                                    </div>
                                    <div className="text-primary">You will save $140 with this code</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SideNavbar */}
            <nav className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <ul className="second-nav list-unstyled p-2 mb-0 m-0 p-0">
                    <li className="">
                        <a href="" className="bg-danger sidebar-user d-flex align-items-center p-3 border-0 mb-0 rounded-4 text-white">
                            <img src="https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png" className="img-fluid rounded-pill me-3 ch-50" alt="#" />
                            <div>
                                <h6 className="mb-0">Hi Guest</h6>
                                <small>+91 12345-67890</small><br />
                                <span className="f-10" style={{ color: "lightgray" }}>Version 1.32</span>
                            </div>
                        </a>
                    </li>
                </ul>
                <div className='scroll-container mt-0' >
                    <ul className="second-nav list-unstyled p-2 m-0 p-0">
                        {navoptions.map((val, index) => {
                            return (
                                <div className='list-of-nav p-1' key={index}>
                                    <li className="list-item">
                                        <Link to={'/' + val.path} className='text-black'>
                                            {val.icon}
                                            {val.title}
                                        </Link>
                                    </li>
                                </div>
                            );
                        })}
                    </ul>
                </div>
                <hr />
                <ul className="list-unstyled d-flex justify-content-evenly  ">
                    <li className=" p-2 bottom_list">
                        <a className="text-danger nav-item text-center" href="index.html" tabIndex="0">
                            <p className="h5 m-0">
                                <i className="icofont-ui-home"></i>
                            </p>
                            Home
                        </a>
                    </li>
                    <li className=" p-2 bottom_list">
                        <a href="" className="nav-item text-center" tabIndex="0">
                            <p className="h5 m-0"><i className="icofont-sale-discount"></i></p>
                            Offer
                        </a>
                    </li>
                    <li className=" p-2 bottom_list">
                        <a href="" className="nav-item text-center" tabIndex="0">
                            <p className="h5 m-0"><i className="icofont-support-faq"></i></p>
                            Help
                        </a>
                    </li>
                </ul>
            </nav>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetStore: () => dispatch(resetStore()),
        addDetails: (dets) => dispatch(addDetails(dets)),
        updateCart: (cart) => dispatch(updateCart(cart)),
    };
};

const mapStateToProps = (state) => {
    return {
        cart: state.shop.cart,
        localCart: state.shop.localCart,
        user: state.shop.user,
        rest: state.shop.rest,
        url: state.shop.url,
        details: state.shop.details,
        token: state.shop.token,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantNav);


