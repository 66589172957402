import React from 'react';
import { connect } from "react-redux";

const FixedBottom = (props) => {
   const user = props.user;
   const cart = props.cart;
   const local = props.localCart;

   return (
      <div className="fixed-bottom bg-white shadow py-2 osahan-nav-bottom" style={{height:"50px"}}>
         <div className="row links">
            <div className="col-4 col-sm-3 text-center">
               <a href="#homepage">
                  <span><i className="bi bi-menu-button-wide-fill h5 "></i></span>
                  <p className="m-0 ">Menu</p>
               </a>
            </div>
            <div className="col-4 col-sm-3 p-0 text-center">
               <a href="#coupon">
                  <span><i className="bi bi-tags h5"></i></span>
                  <p className="m-0 ">Coupons</p>
               </a>
            </div>
            <div className="col-3 col-sm-3 p-0 text-center d-none d-sm-block">
               <a href="#cart">
                  <span className='d-flex align-items-centre justify-content-center'>

                     <i className="bi bi-cart4 d-flex m-0 h1">
                        {!!user.phone_number
                           ? cart.length > 0
                              ? <span className='m-0 ms-1'>
                                 <h6 className='text-danger' style={{ fontStyle: 'normal' }}>
                                    {cart.reduce((acc, item) => acc + Number(item.quantity), 0)}
                                 </h6>
                              </span>
                              : <></>
                           : local.length > 0
                              ? <span className='m-0 ms-1'>
                                 <h6 className='text-danger' style={{ fontStyle: 'normal' }}>
                                    {local.reduce((acc, item) => acc + Number(item.quantity), 0)}
                                 </h6>
                              </span>
                              : <></>
                        }
                     </i>

                  </span>
                  <p className="m-0">Cart</p>
               </a>
            </div>
            <div className="col-4 col-sm-3 p-0 text-center">
               <a href="#orderdetails">
                  <span><i className="bi bi-receipt h5"></i></span>
                  <p className="m-0">History</p>
               </a>
            </div>
         </div>
      </div>
   );
}

const mapStateToProps = (state) => {
   return {
      cart: state.shop.cart,
      localCart: state.shop.localCart,
      user: state.shop.user,
      rest: state.shop.rest,
      url: state.shop.url,
   };
};

export default connect(mapStateToProps)(FixedBottom);
