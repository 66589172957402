export const ADD_RESTAURANT_ID = "ADD_RESTAURANT_ID";

export const ADD_RUNTIME = "ADD_RUNTIME";

export const ADD_USER = "ADD_USER";

export const ADD_GUEST = "ADD_GUEST";

export const ADD_TABLE = "ADD_TABLE";

export const ADD_TOKEN = "ADD_TOKEN";

export const UPDATE_CART = "UPDATE_CART";

export const UPDATE_LOCAL_CART = "UPDATE_LOCAL_CART";

export const ADD_DETAILS = "ADD_DETAILS"; 

export const UPDATE_CART_TOTALS = "UPDATE_CART_TOTALS";

export const UPDATE_CART_COUPONS = "UPDATE_CART_COUPONS";

export const UPDATE_CART_CHARGES = "UPDATE_CART_CHARGES";

export const RESET_STORE = "RESET_STORE";

export const UPDATE_COUPON = "UPDATE_COUPON"

export const UPDATE_HISTORY = "UPDATE_HISTORY"
