import p1 from "../../Assests/img/p1.png";
import p2 from "../../Assests/img/p2.png";

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import img1 from "../../Assests/img/veg.jpeg";
import veg from "../../Assests/img/veg.jpg";
import nonveg from "../../Assests/img/non.jpg";

//Material UI
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import LoadingBar from "react-top-loading-bar";

//API call
import axios from "axios";

import { Modal } from "react-bootstrap";

//router
import { useNavigate } from "react-router-dom";

//redux
import { connect } from "react-redux";
import {
  updateCart,
  updateCartCharges,
  updateCartCoupons,
  updateCartTotals,
  updateCoupon,
  updateHistory,
  updateLocalCart,
} from "../../redux/Cart/cart-actions";

const CoupanHero = (props) => {

  const navigate = useNavigate();

  const history = props.historyCart;
  const url = props.url;
  const user = props.user;
  const token = props.token;

  const [guestModal, setGuestModal] = useState(false);

  const setGuestModalOpen = async () => {
    setGuestModal(true);
  }

  const [coupons, setCoupons] = useState([]);

  const [cop, setCop] = useState('');
  const [copAmount, setCopAmount] = useState('');
  const [copStatus, setCopStatus] = useState('');
  const [copX, setCopX] = useState('');

  //Flow starts here
  useEffect(() => {
    if (user.name) {
      fetchInitialData();
    }
  }, []);

  const fetchInitialData = async () => {
    fetchCoupons();
  };

  const fetchCoupons = async () => {
    const apiUrl = `${url}/api/couponlist`;
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          "business-id": props.rest,
        },
      });
      setCoupons(response.data.data);
    } catch (error) {
      console.log("Error");
    }
  };


  const checkSelectedCoupon = async (value) => {
    const apiUrl = `${url}/api/checkcoupon`;

    var body = {
      couponcode: value.coupon_name
    }

    try {
      const response = await axios.post(apiUrl, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "language-id": 1,
          "business-id": props.rest,
        },
      });
      console.log(response.data);
      props.updateCart(response.data.cart_items);
      props.updateCartCharges(response.data.other_charges);
      props.updateCartTotals(response.data.cart_total_detail);
      props.updateCartCoupons(response.data.coupon_charges);
      props.updateCoupon(response.data.cart_total_detail.coupon_code);
      setCop(response.data.cart_total_detail.coupon_code);
      setCopStatus(response.data.status);
      setCopAmount(response.data.cart_total_detail.coupon_discount_amount);
      setGuestModalOpen();
    } catch (error) {
      console.log("Error");
    }
  }

  const sendToCart = () => {
    navigate('/cart')
  }

  return (
    <>
      {user.name ? (
        <>
          <div className="p-1">
            <form>
              <input
                className="shadow-none form-control border-0 pt-3 pb-3"
                type="text"
                placeholder="Enter Coupon Code"
                aria-label="default input example"
              />
            </form>
          </div>
          <div className="p-3">
            <h5 className="m-0">Available Coupons</h5>
          </div>

          {/*  */}
          <div className="p-3 bg-white">
            {coupons.map((val, key) => {
              return (
                <>
                  <div>

                    <div className="d-flex justify-content-between pb-3 align-items-center">
                      <div className="text-uppercase fw-bold border border-danger text-danger rounded px-2 py-1 h5 m-0">
                        {val.coupon_name}
                      </div>
                      <div>
                        <button onClick={() => checkSelectedCoupon(val)} className="btn btn-danger">
                          Apply
                        </button>
                      </div>
                    </div>
                    <div className="mb-2 d-flex justify-content-between">
                      <div className="h4 m-0">Get {val.coupon_dispercentage}% Off</div>
                    </div>
                    <p className="text-muted">
                      {val.coupon_desc}&nbsp;
                    </p>
                    <div className="text-primary">You can save upto VND {val.coupon_maxdiscount} with this coupon</div>
                  </div>
                  <hr />
                </>
              );
            })}
          </div>
        </>
      ) : (
        <>
          <div className="p-3">
            <h5 className="m-0">Coupon's not available - Please Login</h5>
          </div>
        </>
      )}

      <Modal
        size="md"
        show={guestModal}
        onHide={() => setGuestModal(false)}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-header border-0">
              <div className="d-flex align-items-center ">
                <div className=" text-bold-custom">
                  {copStatus === 200 ? <div>Coupon Applied Successfully</div> : <div>Coupon not applicable for your cart!</div>}
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setGuestModal(false)}
              ></button>
            </div>

            {copStatus === 200 ? <>
              <div className="bg-white px-3 mb-2 d-flex justify-content-between">
                <div className="text-uppercase fw-bold border border-danger text-danger rounded px-2 py-1 h5 m-0">
                  {cop}
                </div>
              </div>
              <div className="text-primary mb-4  px-3 py-1 h7 m-0">You have saved VND {copAmount} with this coupon</div></> : <></>}

            <div className=" d-flex justify-content-around">
              <btn
                className="btn btn-small-text-custom btn-outline-danger col-5"
                onClick={() => setGuestModal(false)}
              >

                Try another coupon
              </btn>
              <btn
                onClick={() => sendToCart()}
                className="btn btn-small-text-custom btn-danger col-5"
              >
                Proceed to cart
              </btn>
            </div>
          </div>
        </div>
      </Modal>
    </>

  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (cart) => dispatch(updateCart(cart)),
    updateCartTotals: (cart) => dispatch(updateCartTotals(cart)),
    updateCartCharges: (cart) => dispatch(updateCartCharges(cart)),
    updateCartCoupons: (cart) => dispatch(updateCartCoupons(cart)),
    updateLocalCart: (cart) => dispatch(updateLocalCart(cart)),
    updateHistory: (cart) => dispatch(updateHistory(cart)),
    updateCoupon: (cart) => dispatch(updateCoupon(cart)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    historyCart: state.shop.historyCart,
    coupon: state.shop.coupon,
    localCart: state.shop.localCart,
    cartTotals: state.shop.cartTotals,
    cartCoupons: state.shop.cartCoupons,
    cartCharges: state.shop.cartCharges,
    user: state.shop.user,
    table: state.shop.table,
    rest: state.shop.rest,
    url: state.shop.url,
    token: state.shop.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoupanHero);
