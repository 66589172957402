import * as actionTypes from "./cart-types";

const INITIAL_STATE = {
  cart: [],
  historyCart: [],
  localCart: [],
  cartTotals: [],
  cartCoupons: [],
  cartCharges: [],
  coupon: '',
  user: [],
  table: [],
  details: "",
  guest: [],
  token: "",
  rest: "",
  runtime: "",
  url: "https://maharaja.app",
};

const cartReducer = (state = INITIAL_STATE, action) => {
  const payload = action.payload;

  switch (action.type) {
    case actionTypes.RESET_STORE:
      return {
        ...state,
        cart: [],
        historyCart: [],
        localCart: [],
        cartTotals: [],
        cartCoupons: [],
        cartCharges: [],
        user: [],
        table: [],
        details: "",
        guest: [],
        token: "",
        rest: "",
        runtime: "",
        url: "http://web-sandbox.dahlia.tech/ras",
      };

    case actionTypes.ADD_RESTAURANT_ID:
      return {
        ...state,
        rest: payload,
      };

    case actionTypes.ADD_DETAILS:
      return {
        ...state,
        details: payload,
      };

    case actionTypes.ADD_TABLE:
      return {
        ...state,
        table: payload,
      };

    case actionTypes.UPDATE_CART_TOTALS:
      return {
        ...state,
        cartTotals: payload,
      };

    case actionTypes.UPDATE_COUPON:
      return {
        ...state,
        coupon: payload,
      };

    case actionTypes.UPDATE_HISTORY:
      return {
        ...state,
        historyCart: payload,
      };

    case actionTypes.UPDATE_CART_COUPONS:
      return {
        ...state,
        cartCoupons: payload,
      };

    case actionTypes.UPDATE_CART_CHARGES:
      return {
        ...state,
        cartCharges: payload,
      };

    case actionTypes.ADD_USER:
      return {
        ...state,
        user: payload,
      };

    case actionTypes.ADD_GUEST:
      return {
        ...state,
        guest: payload,
      };

    case actionTypes.UPDATE_CART:
      return {
        ...state,
        cart: payload,
      };

    case actionTypes.UPDATE_LOCAL_CART:
      return {
        ...state,
        localCart: payload,
      };

    case actionTypes.ADD_TOKEN:
      return {
        ...state,
        token: payload,
      };

    case actionTypes.ADD_RUNTIME:
      return {
        ...state,
        runtime: payload,
      };

    default:
      return state;
  }
};

export default cartReducer;
