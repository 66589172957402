import React from 'react'
import { Link } from 'react-router-dom'

import Green from "../Assests/img/green-tick-preview.png"
import { connect } from 'react-redux';

const OrderConfirmed = (props) => {
    const history = props.history;

    return (
        <>
            <div className="bg-white shadow-sm p-3 sticky-top">
                <div className="d-flex align-items-center">
                    <div className="gap-3 d-flex align-items-center">
                        <Link to={'/homepage'}>
                            <i className="bi bi-arrow-left d-flex text-danger h2 m-0 back-page"></i></Link>
                        <h3 className="fw-bold m-0">Order Confirmation</h3>
                    </div>
                    <div className="ms-auto gap-3 d-flex align-items-center">
                        <a href="#/orderdetails" className="btn btn-white border text-muted btn-sm rounded">Order Details</a>

                    </div>
                </div>
            </div>
            <div className="my-5">
                <div className="text-center">
                    <div className="mb-3 px-5 p-5"><img src={Green} className="img-fluid set-image-height-width" alt="#" /></div>
                    <div className="h1 text-success mb-1">
                        Order placed successfully
                    </div>
                    <p className="text-muted h3 text-success ">
                        Order No: {history.data.order_number}
                    </p>
                </div>
            </div>
            <div className="d-grid fixed-bottom gap-2 p-3">
                <a href="#/orderdetails" className="btn btn-outline-danger btn-lg">Order Details</a>
                <a href="#/homepage" className="btn btn-danger btn-lg">Continue to menu</a>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        history: state.shop.historyCart,
    };
};

export default connect(mapStateToProps)(OrderConfirmed);