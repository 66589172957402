import React from 'react'
import CartNav from './CartNav'
import CartDetail from './CartDetail'
import Usecoupan from './Usecoupan'
import ItemsTotal from './ItemsTotal'
import CancellationPolicy from './CancellationPolicy'

const Cart = () => {
    return (
        <>
            <CartNav />
            <CartDetail/>
            {/* <Usecoupan/>
            <ItemsTotal/>
            <CancellationPolicy/> */}
        </>
    )
}

export default Cart