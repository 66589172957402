import React from 'react'
import Coupan from '../Component/Coupans/Coupan'

const CoupanPage = () => {
  return (
    <>
        <Coupan/>
    </>
  )
}

export default CoupanPage