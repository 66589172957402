import React, { useEffect, useState } from "react";
import FixedBottom from "./FixedBottom";
import RestaurantNav from "../Restaurant/RestaurantNav";
import ResturantHero from "../Restaurant/ResturantHero";
import ResturantTabs from "../Restaurant/RestaurantTabsGuest";

//Material UI
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//API call
import axios from "axios";

//redux
import { connect } from "react-redux";
import { addRestaurantID, addTable } from "../../redux/Cart/cart-actions";
import { addRuntime } from "../../redux/Cart/cart-actions";

const HomePage = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsLoading(false);
  };

  const url = props.url;
  const table_id = "1710640550";
  const pathName = window.location.href;
  const psuedo_pathName =
    "https://sandbox.dahlia.tech/maharaja/#/?business_id=eyJpdiI6IlQ3a1NkUWZ3eUcweVV1ZXJHQ3lMVnc9PSIsInZhbHVlIjoiM1N5eWY1ZU1zRTJzSW01R2I1d09YUT09IiwibWFjIjoiOTQ4M2YzMzU0NDQ4OTEzZjdmNmQxNDc4YmM3YjFjOTkzNWQzZWE1MDMxYTc3YzYyYWEwY2UxNjRmODY3M2ZkZCIsInRhZyI6IiJ9&table_id=1710640550";

  //Flow starts here
  useEffect(() => {
    if (props.runtime != "1") {
      fetchInitialData();
    }
    else if (pathName.includes("business")) {
      fetchInitialData();
    }
  }, []);

  const fetchInitialData = async () => {
    const apiUrl = `${url}/api/businesstoken`;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("url", pathName);
      //formData.append('qrcode_number', table_id);
      const response = await axios.post(apiUrl, formData);
      props.addRestaurantID(response.data.data.business_id);
      props.addTable(response.data.data.table_id);
      props.addRuntime("1");
      setIsLoading(false);
    } catch (error) {
      console.log("Error");
    }
  };

  return (
    <>
      {/* <Navbar/>
            <TopBrands/>
            <EatHappy/>
            <Resturants/>
            <FixedBottom/> */}

      {isLoading ? (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <RestaurantNav />
          {/* <ResturantHero /> */}
          <ResturantTabs />
          <FixedBottom />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRestaurantID: (business_id) => dispatch(addRestaurantID(business_id)),
    addRuntime: (frequency) => dispatch(addRuntime(frequency)),
    addTable: (table) => dispatch(addTable(table)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    user: state.shop.user,
    rest: state.shop.rest,
    runtime: state.shop.runtime,
    url: state.shop.url,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
