import './App.css';
import HomePage from './Pages/HomePage';
import AddressPage from './Pages/AddressPage';
import OrderDetailPage from './Pages/OrderDetailPage';
import CouponPage from './Pages/CoupanPage';
import CartPage from './Pages/CartPage';
import RestuarantPage from './Pages/RestuarantPage';
import Login from './Pages/Login'
import EnterOTP from './Pages/EnterOTP';
import LandingPage from './Pages/LandingPage';
import OrderConfirmed from './Pages/OrderConfirmed';

import {
  Routes,
  Route,
  Outlet

} from "react-router-dom";
import SIgnUp from './Pages/SIgnUp';


function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/homepage" element={<HomePage />} />
        <Route exact path="/orderdetails" element={<OrderDetailPage />} />
        <Route exact path="/myaddress" element={<AddressPage />} />
        <Route exact path="/coupon" element={<CouponPage />} />
        <Route exact path="/cart" element={<CartPage />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SIgnUp />} />
        <Route exact path="/enter-otp" element={<EnterOTP />} />
        <Route exact path="/restaurant" element={<RestuarantPage />} />
        <Route exact path="/order-confirmed" element={<OrderConfirmed />} />
        <Route path="/*" element={<HomePage />} />
      </Routes>


    </>
  );
}

export default App;
