import React, { useEffect, useState } from "react";

//image
import image from "../Assests/img/image1.jpg";

//router
import { useNavigate } from "react-router-dom";

import toast, { Toaster } from 'react-hot-toast';


//Material UI
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

//API call
import axios from "axios";

import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

//redux
import { connect } from "react-redux";
import FixedBottom from "../Component/Home/FixedBottom";
//import { Modal } from "@mui/material";

const Login = (props) => {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("91");

  const settingPhone = (val) => {
    setPhone(val);
    localStorage.setItem("phone", val);
    localStorage.setItem("code", code);
  };

  const sendApiCall = async () => {
    const apiUrl = `${url}/api/customersignin`;
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("country_code", code);
      formData.append("phone_number", phone);
      formData.append("type", 2)
      const response = await axios.post(apiUrl, formData);
      console.log("response", response);
      if (response.data.status === 200) {
        toast.success(response.data.message);
        navigate('/enter-otp')
      } else {
        toast.error(response.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.log("Error");
    }
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const url = props.url;

  console.log(phone);

  //Flow starts here
  useEffect(() => { }, []);

  return (
    <>
      <div>
        <img src={image} alt="" className="img-fluid sign-in-image-css" />
      </div>
      <div className="bg-white p-4">
        <div className="text-center px-4 mb-4">
          <h1 className="fw-bold">MAHARAJA</h1>
          <h5 className="">Digital Food Ordering Solutions</h5>
        </div>
        <div className="d-flex gap-3 align-items-center justify-content-center mb-2">
          <div className="w-25">
            <hr />
          </div>
          <div className="text-muted">Log in or Sign up</div>
          <div className="w-25">
            <hr />
          </div>
        </div>
        <div className="mb-3">
          <form>
            <div className="input-group mb-2">
              <span className="input-group-text rounded-3 bg-white me-2 p-0">
                <select
                  className="form-select border-0"
                  aria-label="Default select example"
                >
                  <option selected>+91</option>
                  <option value="1">+01</option>
                  <option value="2">+65</option>
                  <option value="3">+92</option>
                </select>
              </span>
              <input
                type="number"
                className="shadow-none form-control rounded-3"
                placeholder="Enter Your Number"
                value={phone}
                onChange={(e) => settingPhone(e.target.value)}
                required
              />
            </div>
            <p className="small text-muted">
              If you don't have an account,
              <a href="/#/signup" className="text-danger underline">
                Create Account
              </a>
            </p>
            <div className="d-grid">
              <btn className="btn btn-danger btn-lg" onClick={() => sendApiCall()}>
                Continue
              </btn>
            </div>
          </form>
        </div>

        <div className="text-center">
          <p className="small mb-2 text-muted">By continuing you agree to our</p>
          <div className="d-flex justify-content-center gap-3 small">
            <div>
              <a href="#" className="link-dark">
                Terms of Service
              </a>
            </div>
            <div>
              <a href="#" className="link-dark">
                Privacy Policy
              </a>
            </div>
            <div>
              <a href="#" className="link-dark">
                Content Policy
              </a>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    user: state.shop.user,
    rest: state.shop.rest,
    url: state.shop.url,
  };
};

export default connect(mapStateToProps)(Login);
