import React from 'react'
import CoupanNavbar from './CoupanNavbar'
import CoupanHero from './CoupanHero'

const Coupan = () => {
    return (
        <>
            <CoupanNavbar />
            <CoupanHero />
            
        </>
    )
}

export default Coupan