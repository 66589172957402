import * as actionTypes from './cart-types';

export const addRestaurantID = (id) => {
    return {
        type : actionTypes.ADD_RESTAURANT_ID,
        payload: id
}}

export const addRuntime = (id) => {
    return {
        type : actionTypes.ADD_RUNTIME,
        payload: id
}}

export const addUser = (id) => {
    return {
        type : actionTypes.ADD_USER,
        payload: id
}}

export const addGuest = (id) => {
    return {
        type : actionTypes.ADD_GUEST,
        payload: id
}}

export const addToken = (id) => {
    return {
        type : actionTypes.ADD_TOKEN,
        payload: id
}}

export const updateCart = (id) => {
    return {
        type : actionTypes.UPDATE_CART,
        payload: id
}}

export const updateLocalCart = (id) => {
    return {
        type : actionTypes.UPDATE_LOCAL_CART,
        payload: id
}}

export const addDetails = (id) => {
    return {
        type : actionTypes.ADD_DETAILS,
        payload: id
}}

export const updateCartTotals = (id) => {
    return {
        type : actionTypes.UPDATE_CART_TOTALS,
        payload: id
}}

export const updateCartCoupons = (id) => {
    return {
        type : actionTypes.UPDATE_CART_COUPONS,
        payload: id
}}

export const updateCartCharges = (id) => {
    return {
        type : actionTypes.UPDATE_CART_CHARGES,
        payload: id
}}

export const addTable = (id) => {
    return {
        type : actionTypes.ADD_TABLE,
        payload: id
}}

export const resetStore = () => {
    return {
        type : actionTypes.RESET_STORE
}}

export const updateHistory = (id) => {
    return {
        type : actionTypes.UPDATE_HISTORY,
        payload: id
}}

export const updateCoupon = (id) => {
    return {
        type : actionTypes.UPDATE_COUPON,
        payload: id
}}


