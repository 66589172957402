import React from 'react'
import { Link } from 'react-router-dom'


const AddressNav = () => {
    const navoptions = [
        {
            id: 0,
            title: "Homepage",
            path: "homepage",
            icon: <i className="bi bi-house me-2"></i>
        },
        {
            id: 1,
            title: "Enable Location",
            path: "",
            icon: <i className="bi bi-geo-alt me-2"></i>
        },
        {
            id: 2,
            title: "Add Location",
            path: "",
            icon: <i className="bi bi-geo me-2"></i>
        },
        {
            id: 3,
            title: "Coupons",
            path: "#coupon",
            icon: <i className="bi bi-ticket-detailed me-2"></i>
        },
        {
            id: 4,
            title: "Offers",
            path: "",
            icon: <i className="bi bi-percent me-2"></i>
        },
        {
            id: 5,
            title: "Wallet",
            path: "",
            icon: <i className="bi bi-wallet me-2"></i>
        },
        {
            id: 6,
            title: "Notification",
            path: "",
            icon: <i className="bi bi-bell-slash me-2"></i>
        },
        {
            id: 7,
            title: "Setting",
            path: "",
            icon: <i className="bi bi-gear me-2"></i>
        },
        {
            id: 8,
            title: "Search List",
            path: "",
            icon: <i className="bi bi-search me-2"></i>
        },
        {
            id: 9,
            title: "Store",
            path: "",
            icon: <i className="bi bi-shop me-2"></i>
        },
        {
            id: 10,
            title: "Cart",
            path: "cart",
            icon: <i className="bi bi-bag me-2"></i>
        },
        {
            id: 11,
            title: "Checkout",
            path: "",
            icon: <i className="bi bi-cart me-2"></i>
        },
        {
            id: 12,
            title: "Payment",
            path: "",
            icon: <i className="bi bi-credit-card me-2"></i>
        },
        {
            id: 13,
            title: "Add Card",
            path: "",
            icon: <i className="bi bi-credit-card-2-front me-2"></i>
        },
        {
            id: 14,
            title: "Personal Info",
            path: "",
            icon: <i className="bi bi-person me-2"></i>
        },
        {
            id: 15,
            title: "Edit Profile",
            path: "",
            icon: <i className="bi bi-pencil me-2"></i>
        },
        {
            id: 16,
            title: "My Address",
            path: "myaddress",
            icon: <i className="bi bi-map me-2"></i>
        },
        {
            id: 17,
            title: "Your Order",
            path: "",
            icon: <i className="bi bi-view-list me-2"></i>
        },
        {
            id: 18,
            title: "Order Confirm",
            path: "",
            icon: <i className="bi bi-bag-check me-2"></i>
        },
        {
            id: 19,
            title: "Order Details",
            path: "orderdetails",
            icon: <i className="bi bi-list-nested me-2"></i>
        },
        {
            id: 20,
            title: "Support",
            path: "",
            icon: <i className="bi bi-question-circle me-2"></i>
        },
    ]
    return (
        <>
            <div className="bg-white shadow-sm p-3">
                <div className="d-flex align-items-center">
                    <div className="gap-3 d-flex align-items-center">
                        <Link to={'/homepage'}>
                            <i className="bi bi-arrow-left d-flex text-danger h2 m-0 back-page"></i></Link>
                        <h3 className="fw-bold m-0">My Addresses</h3>
                    </div>
                    <div className="ms-auto gap-3 d-flex align-items-center">
                        <a href="" className="link-dark"><i className="bi bi-bell d-flex m-0 h2"></i></a>
                        <a className="text-dark m-0" href="#">
                            <i className="bi bi-list d-flex m-0 h1" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">

                            </i></a>
                    </div>
                </div>
            </div>

            {/* SideNavbar */}
            <nav className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <ul className="second-nav list-unstyled p-2 mb-0 m-0 p-0">
                    <li className="">
                        <a href="" className="bg-danger sidebar-user d-flex align-items-center p-3 border-0 mb-0 rounded-4 text-white">
                            <img src="https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png" className="img-fluid rounded-pill me-3 ch-50" alt="#" />
                            <div>
                                <h6 className="mb-0">Hi Your Name</h6>
                                <small>+91 12345-67890</small><br />
                                <span className="f-10" style={{ color: "lightgray" }}>Version 1.32</span>
                            </div>
                        </a>
                    </li>
                </ul>
                <div className='scroll-container mt-0' >
                    <ul className="second-nav list-unstyled p-2 m-0 p-0">
                        {navoptions.map((val, index) => {
                            return (
                                <div className='list-of-nav p-1' key={index}>
                                    <li className="list-item">
                                        <Link to={'/' + val.path} className='text-black'>
                                            {val.icon}
                                            {val.title}
                                        </Link>
                                    </li>
                                </div>
                            );
                        })}
                    </ul>
                </div>
                <hr />
                <ul className="list-unstyled d-flex justify-content-evenly  ">
                    <li className=" p-2 bottom_list">
                        <a className="text-danger nav-item text-center" href="index.html" tabIndex="0">
                            <p className="h5 m-0">
                                <i className="icofont-ui-home"></i>
                            </p>
                            Home
                        </a>
                    </li>
                    <li className=" p-2 bottom_list">
                        <a href="" className="nav-item text-center" tabIndex="0">
                            <p className="h5 m-0"><i className="icofont-sale-discount"></i></p>
                            Offer
                        </a>
                    </li>
                    <li className=" p-2 bottom_list">
                        <a href="" className="nav-item text-center" tabIndex="0">
                            <p className="h5 m-0"><i className="icofont-support-faq"></i></p>
                            Help
                        </a>
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default AddressNav