/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import img1 from "../../Assests/img/veg.jpeg";
import veg from "../../Assests/img/veg.jpg";
import nonveg from "../../Assests/img/non.jpg";

//Material UI
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import LoadingBar from "react-top-loading-bar";

//API call
import axios from "axios";

import { Modal } from "react-bootstrap";

//router
import { useNavigate } from "react-router-dom";

//redux
import { connect } from "react-redux";
import {
  updateCart,
  updateCartCharges,
  updateCartCoupons,
  updateCartTotals,
  updateHistory,
  updateLocalCart,
} from "../../redux/Cart/cart-actions";

const YourOrder = (props) => {
  const history = props.historyCart;

  const user = props.user;

  const det = props.details;

  return (
    <>
      {history.data?.order_details.length > 0 ? <>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div className="d-flex justify-content-between border border-primary rounded text-primary p-2 h4 mb-5">Order No. - {history.data.order_number}</div>
          {/* <div><button className="btn btn-outline-secondary rounded-pill btn-sm">Mark as favourite</button></div> */}
        </div>
        {user.name
          ? history.data.order_details.map((val, index) => {
            return (
              <>

              </>
            );
          })
          : history.data.order_details.map((val, index) => {
            return (
              <>
                <div className="m-0">
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <img
                      src={val.item_type === "Non Veg" ? nonveg : veg}
                      alt=""
                      className="img-fluid ch-20"
                    />
                    <div className="lh-sm">
                      <h4 className="mb-1">{val.item_name}</h4>
                      {val.addons_menu_item.length > 0 ? (
                        val.addons_menu_item.map((x) => (
                          <div className="text-muted fw-normal">
                            + {x.addon_name}
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="rounded border border-success small px-2 d-flex">
                      {val.quantity}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div className="fs-5 fw-bold">
                      {det.currency_code} {val.item_total_amount_with_addon}
                    </div>
                  </div>
                </div>
                <hr />
              </>
            );
          })}
        {user.name ? (
          <div className="bg-white p-3 mb-3 shadow-sm"></div>
        ) : (
          <div className="bg-white  mb-5 shadow-sm">
            {/* User not present */}
            {/* User not present */}
            {/* User not present */}
            {/* User not present */}
            {/* User not present */}
            <div className="d-flex justify-content-between mb-2">
              <div>Items Total</div>
              <div>{det.currency_code} {history.data.cart_total_amount_with_addon}</div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div>Total Tax</div>
              <div>{det.currency_code} {history.data.total_tax_amount}</div>
            </div>
            <div className="d-flex justify-content-between h4 fw-bold mb-2">
              <div>Grand Total</div>
              <div>{det.currency_code} {history.data.total_amount}</div>
            </div>
            <div className="p-3 fixed-bottom">
              <div className="d-grid">
                <a href="#/homepage" className="btn btn-danger btn-lg">Return to Homepage</a>
              </div>
            </div>
          </div>

        )}</> : <>
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="text-center">
            <h1 className="mb-4">Your Order History is Empty</h1>
            <p className="lead">Looks like you haven't ordered anything yet.</p>
          </div>
        </div>
      </>}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (cart) => dispatch(updateCart(cart)),
    updateCartTotals: (cart) => dispatch(updateCartTotals(cart)),
    updateCartCharges: (cart) => dispatch(updateCartCharges(cart)),
    updateCartCoupons: (cart) => dispatch(updateCartCoupons(cart)),
    updateLocalCart: (cart) => dispatch(updateLocalCart(cart)),
    updateHistory: (cart) => dispatch(updateHistory(cart)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    historyCart: state.shop.historyCart,
    localCart: state.shop.localCart,
    cartTotals: state.shop.cartTotals,
    cartCoupons: state.shop.cartCoupons,
    cartCharges: state.shop.cartCharges,
    user: state.shop.user,
    details: state.shop.details,
    table: state.shop.table,
    rest: state.shop.rest,
    url: state.shop.url,
    token: state.shop.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(YourOrder);
