import React from 'react'
import AddressNav from './AddressNav'
import AddAddress from './AddAddress'
import './Address.css'
const Address = () => {
    return (
        <>
                <AddressNav />
                <AddAddress />
        </>
    )
}

export default Address