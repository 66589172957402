/* eslint-disable no-unused-vars */
import OrderNav from './OrderNav'
import OrderPerson from './OrderPerson'
import YourOrder from './YourOrder'
import Totalprice from './Totalprice'
import Details from './Details'
import OrderHistory from './OrderHistory'

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import img1 from "../../Assests/img/veg.jpeg";
import veg from "../../Assests/img/veg.jpg";
import nonveg from "../../Assests/img/non.jpg";

//Material UI
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import LoadingBar from "react-top-loading-bar";

//API call
import axios from "axios";

import { Modal } from "react-bootstrap";

//router
import { useNavigate } from "react-router-dom";

//redux
import { connect } from "react-redux";
import {
  updateCart,
  updateCartCharges,
  updateCartCoupons,
  updateCartTotals,
  updateHistory,
  updateLocalCart,
} from "../../redux/Cart/cart-actions";

const OrderDetail = (props) => {

  const user = props.user; 

  return (
    <>
      <OrderNav />
      {!!user.phone_number ? <>
        <div className="p-3 mt-1 bg-white">
          <OrderHistory />
        </div></> : <>
        <div className="p-3 mt-1 bg-white">
          <YourOrder />
        </div></>}
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCart: (cart) => dispatch(updateCart(cart)),
    updateCartTotals: (cart) => dispatch(updateCartTotals(cart)),
    updateCartCharges: (cart) => dispatch(updateCartCharges(cart)),
    updateCartCoupons: (cart) => dispatch(updateCartCoupons(cart)),
    updateLocalCart: (cart) => dispatch(updateLocalCart(cart)),
    updateHistory: (cart) => dispatch(updateHistory(cart)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    historyCart: state.shop.historyCart,
    localCart: state.shop.localCart,
    cartTotals: state.shop.cartTotals,
    cartCoupons: state.shop.cartCoupons,
    cartCharges: state.shop.cartCharges,
    user: state.shop.user,
    table: state.shop.table,
    rest: state.shop.rest,
    url: state.shop.url,
    token: state.shop.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);