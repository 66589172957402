/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import LoadingBar from 'react-top-loading-bar'

//image
import image from "../Assests/img/image1.jpg";

//router
import { useNavigate } from "react-router-dom";

import toast, { Toaster } from 'react-hot-toast';


//Material UI
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { addToken, updateCart, updateCartCharges, updateCartCoupons, updateCartTotals, updateLocalCart } from "../redux/Cart/cart-actions";
import { addUser } from "../redux/Cart/cart-actions";

//API call
import axios from "axios";

import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

//redux
import { connect } from "react-redux";
//import { Modal } from "@mui/material";

const EnterOTP = (props) => {

  const localCart = props.localCart;

  const [progress, setProgress] = useState(0)
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const [code, setCode] = useState(localStorage.getItem("code"));

  const [otp, setOTP] = useState('')

  const settingOTP = async (val) => {
    setOTP(val);
    if (val.length === 6) {
      const apiUrl = `${url}/api/customerverify`;
      try {
        setProgress(20);
        setIsLoading(true);
        const formData = new FormData();
        formData.append("otp", val);
        formData.append("phone_number", phone);
        //formData.append("type",1)
        const response = await axios.post(apiUrl, formData);
        if (response.data.status === 200) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        props.addUser(response.data.customer);
        props.addToken(response.data.token);
        setProgress(100)
        if (localCart.length > 0) {
          var item = [];
          localCart.map((x) => (
            item.push({
              item_id: x.id,
              quantity: x.quantity,
              is_delete: x.is_delete,
              addons: x.addons,
              combo_options: [],
              cart_item_id: null,
            })
          ))
          var body = {
            menuitem: item,
            applied_on: 2,
            api_type: 1,
            takeaway_address: null,
          };

          const apiUrl = `${url}/api/addupdatecartitem`;
          try {
            setProgress(10);
            const response1 = await axios.post(apiUrl, body, {
              headers: {
                Authorization: `Bearer ${response.data.token}`,
                "language-id": 1,
                "business-id": props.rest,
              },
            });
            console.log(response1.data);
            props.updateCart(response1.data.cart_items);
            props.updateCartCharges(response1.data.other_charges);
            props.updateCartTotals(response1.data.cart_total_detail);
            props.updateCartCoupons(response1.data.coupon_charges);
            props.updateLocalCart([]);
            setProgress(100);
          } catch (error) {
            toast.error(error.message);
            console.log("Error");
          }
        }
        navigate('/homepage')
        setIsLoading(false);
      } catch (error) {
        console.log("Error");
      }
    }
  }

  function timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const url = props.url;

  //Flow starts here
  useEffect(() => { }, []);

  return (
    <>
      <LoadingBar
        color='#f06c14'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div>
        <img src={image} alt="" className="img-fluid sign-in-image-css" />
      </div>
      <div className="bg-white p-4">
        <div className="text-center px-4 mb-4">
          <h1 className="fw-bold">MAHARAJA</h1>
          <h5 className="">Digital Food Ordering Solutions</h5>
        </div>

        <div className="text-center my-5 h5">
          <div>We have sent a verification code to</div>
          <div className="fw-bold mt-2">+{code} {phone}</div>
        </div>
        <div className="text-center px-5">
          {/* OTP boxes commented */}
          {/* <div className="d-flex gap-3 mb-3">
            <div><input className="shadow-none form-control px-0 text-center" maxLength={1} type="text" placeholder="" aria-label="default input example" /></div>
            <div><input className="shadow-none form-control px-0 text-center" maxLength={1} type="text" placeholder="" aria-label="default input example" /></div>
            <div><input className="shadow-none form-control px-0 text-center" maxLength={1} type="text" placeholder="" aria-label="default input example" /></div>
            <div><input className="shadow-none form-control px-0 text-center" maxLength={1} type="text" placeholder="" aria-label="default input example" /></div>
            <div><input className="shadow-none form-control px-0 text-center" maxLength={1} type="text" placeholder="" aria-label="default input example" /></div>
            <div><input className="shadow-none form-control px-0 text-center" maxLength={1} type="text" placeholder="" aria-label="default input example" /></div>
         </div> */}
          <div className="input-group mb-2">
            <input
              type="number"
              className="shadow-none form-control rounded-3"
              placeholder="Enter The OTP"
              value={otp}
              onChange={(e) => settingOTP(e.target.value)}
              required
            />
          </div>
          <div className="gap-2 mb-3">
            <button type="button" className="btn btn-outline-secondary btn-sm">Resend SMS</button>
          </div>
        </div>

        <div className="text-center">
          <p className="small mb-2 text-muted">By continuing you agree to our</p>
          <div className="d-flex justify-content-center gap-3 small">
            <div>
              <a href="#" className="link-dark">
                Terms of Service
              </a>
            </div>
            <div>
              <a href="#" className="link-dark">
                Privacy Policy
              </a>
            </div>
            <div>
              <a href="#" className="link-dark">
                Content Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToken: (token) => dispatch(addToken(token)),
    addUser: (user) => dispatch(addUser(user)),
    updateCart: (cart) => dispatch(updateCart(cart)),
    updateCartTotals: (cart) => dispatch(updateCartTotals(cart)),
    updateCartCharges: (cart) => dispatch(updateCartCharges(cart)),
    updateCartCoupons: (cart) => dispatch(updateCartCoupons(cart)),
    updateLocalCart: (cart) => dispatch(updateLocalCart(cart)),
  };
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
    localCart: state.shop.localCart,
    cartTotals: state.shop.cartTotals,
    cartCoupons: state.shop.cartCoupons,
    cartCharges: state.shop.cartCharges,
    user: state.shop.user,
    table: state.shop.table,
    rest: state.shop.rest,
    url: state.shop.url,
    token: state.shop.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterOTP);
