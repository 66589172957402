import React, { useEffect, useState } from "react";
import veg from "../../Assests/img/veg.jpg";
import nonveg from "../../Assests/img/non.jpg";

//Material UI
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import LoadingBar from "react-top-loading-bar";

//API call
import axios from "axios";

import { Modal } from "react-bootstrap";

//router
import { useNavigate } from "react-router-dom";

//redux
import { connect } from "react-redux";
import {
    updateCart,
    updateCartCharges,
    updateCartCoupons,
    updateCartTotals,
    updateHistory,
    updateLocalCart,
} from "../../redux/Cart/cart-actions";
import toast from "react-hot-toast";

const CartDetail = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setIsLoading(false);
    };

    const [guestModal, setGuestModal] = useState(false);

    const user = props.user;

    const [progress, setProgress] = useState(0);

    const navigate = useNavigate();

    const cart = props.cart;
    const localCart = props.localCart;
    const cartTotals = props.cartTotals;
    const cartCharges = props.cartCharges;
    const cartCoupons = props.cartCoupons;
    const coupon = props.coupon;

    const token = props.token;

    const url = props.url;

    //Flow starts here
    useEffect(() => {
        if (!!user.phone_number) {
            console.log("User is present");
        } else {
            console.log("User is not present");
            fetchInitialData();
        }
    }, []);

    useEffect(() => {
        pushCartToGuest();
    }, [localCart]);

    const det = props.details;

    const fetchInitialData = async () => {
        setIsLoading(true);
        pushCartToGuest();
    };

    const pushCartToGuest = async () => {
        var item = [];
        localCart.map((x) =>
            item.push({
                item_id: x.id,
                quantity: x.quantity,
                is_delete: x.is_delete,
                addons: x.addons,
            }),
        );
        var body = {
            menuitem: item,
            applied_on: 1,
            api_type: 1,
            takeaway_address: null,
        };

        const apiUrl = `${url}/api/guestviewcartitem`;
        try {
            setProgress(10);
            const response = await axios.post(apiUrl, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "language-id": 1,
                    "business-id": props.rest,
                },
            });
            props.updateCart(response.data.cart_items);
            props.updateCartCharges(response.data.other_charges);
            props.updateCartTotals(response.data.cart_total_detail);
            //props.updateCartCoupons(response.data.coupon_charges);
            setIsLoading(false);
            setProgress(100);
        } catch (error) {
            console.log("Error");
        }
    };

    function convertToAddonArray(arr) {
        var newvar = [];
        {
            arr.map((item) => {
                newvar.push(item.id);
            });
        }
        return newvar;
    }

    const addFromFinalPopupLocal = async (value) => {
        const ex = localCart.find(
            (x) =>
                x.id === value.id &&
                JSON.stringify(convertToAddonArray(x.addons)) ===
                JSON.stringify(convertToAddonArray(value.addons.sort((a, b) => a.id - b.id))),
        );

        var item = [];

        if (ex) {
            localCart.map((x) =>
                x.id === value.id &&
                    JSON.stringify(convertToAddonArray(x.addons)) ===
                    JSON.stringify(convertToAddonArray(value.addons.sort((a, b) => a.id - b.id)))
                    ? x.quantity === 1 && item.push({
                        id: x.id,
                        quantity: parseInt(x.quantity) + 1,
                        is_delete: 0,
                        addons: x.addons,
                    })
                    : item.push({
                        id: x.id,
                        quantity: x.quantity,
                        is_delete: 0,
                        addons: x.addons,
                    }),
            );
            console.log(item);
            props.updateLocalCart(item);
        }
    };

    const subtractFromFinalPopupLocal = async (value) => {
        const ex = localCart.find(
            (x) =>
                x.id === value.id &&
                JSON.stringify(convertToAddonArray(x.addons)) ===
                JSON.stringify(convertToAddonArray(value.addons.sort((a, b) => a.id - b.id))),
        );

        var item = [];

        if (ex) {
            localCart.map((x) =>
                x.id === value.id &&
                    JSON.stringify(convertToAddonArray(x.addons)) ===
                    JSON.stringify(convertToAddonArray(value.addons.sort((a, b) => a.id - b.id)))
                    ? x.quantity === 1
                        ? ""
                        : item.push({
                            id: x.id,
                            quantity: parseInt(x.quantity) - 1,
                            is_delete: 0,
                            addons: x.addons,
                        })
                    : item.push({
                        id: x.id,
                        quantity: x.quantity,
                        is_delete: 0,
                        addons: x.addons,
                    }),
            );
            console.log(item);
            props.updateLocalCart(item);
        }
    };

    const subtractFromFinalPopup = async (value) => {
        const body = {
            menuitem: [
                {
                    item_id: value.id,
                    quantity: parseInt(value.quantity) - 1,
                    cart_item_id: value.cart_item_id,
                    is_delete: 0,
                    addons: value.addons,
                    combo_options: [],
                },
            ],
            applied_on: 2,
            api_type: 1,
        };
        const apiUrl = `${url}/api/addupdatecartitem`;
        try {
            setProgress(10);
            const response = await axios.post(apiUrl, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "language-id": 1,
                    "business-id": props.rest,
                },
            });
            props.updateCart(response.data.cart_items);
            props.updateCartCharges(response.data.other_charges);
            props.updateCartTotals(response.data.cart_total_detail);
            props.updateCartCoupons(response.data.coupon_charges);
            setProgress(100);
        } catch (error) {
            console.log("Error");
        }
    };

    const addFromFinalPopup = async (value) => {
        const body = {
            menuitem: [
                {
                    item_id: value.id,
                    quantity: parseInt(value.quantity) + 1,
                    cart_item_id: value.cart_item_id,
                    is_delete: 0,
                    addons: value.addons,
                    combo_options: [],
                },
            ],
            applied_on: 2,
            api_type: 1,
        };
        const apiUrl = `${url}/api/addupdatecartitem`;
        try {
            setProgress(10);
            const response = await axios.post(apiUrl, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "language-id": 1,
                    "business-id": props.rest,
                },
            });
            props.updateCart(response.data.cart_items);
            props.updateCartCharges(response.data.other_charges);
            props.updateCartTotals(response.data.cart_total_detail);
            props.updateCartCoupons(response.data.coupon_charges);
            setProgress(100);
        } catch (error) {
            console.log("Error");
        }
    };

    const placeOrder = async () => {
        const body = {
            api_type: 1,
            address_id: null,
            cooking_instruction: null,
            payment_type: 0,
            applied_on: 1,
            takeaway_address: null,
            table_reservation_id: null,
        };
        const apiUrl = `${url}/api/placeorder`;
        try {
            setProgress(10);
            const response = await axios.post(apiUrl, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "language-id": 1,
                    "business-id": props.rest,
                    "table-id": props.table,
                },
            });
            props.updateHistory(response.data);
            props.updateLocalCart([]);
            props.updateCart([]);
            props.updateCartCharges("");
            props.updateCartTotals("");
            navigate("/order-confirmed");
            setProgress(100);
        } catch (error) {
            console.log("Error");
        }
    };

    const setGuestModalOpen = async () => {
        setGuestModal(true);
    };

    const handleOrder = async () => {
        if (!!user?.phone_number) {
            placeOrder();
        } else {
            setGuestModalOpen();
        }
    };

    const [name, setName] = useState('');
    const [showingName, setShowingName] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault();
        if (name) {
            guestPlaceOrder();
        } else {
            alert('Please enter your name');
        }
    };
    const guestPlaceOrder = async () => {
        var item = [];
        localCart.map((x) =>
            item.push({
                item_id: x.id,
                quantity: x.quantity,
                is_delete: x.is_delete,
                addons: x.addons,
            }),
        );
        const body = {
            menuitem: item,
            api_type: 1,
            address_id: null,
            cooking_instruction: null,
            payment_type: 0,
            applied_on: 1,
            takeaway_address: null,
            table_reservation_id: null,
        };
        const apiUrl = `${url}/api/guestplaceorder`;
        try {
            setProgress(10);
            const response = await axios.post(apiUrl, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "language-id": 1,
                    "business-id": props.rest,
                    "table-id": props.table,
                },
            });
            props.updateHistory(!user?.phone_number ? response.data : []);
            props.updateLocalCart([]);
            props.updateCart([]);
            props.updateCartCharges("");
            props.updateCartTotals("");
            navigate("/order-confirmed");
            setProgress(100);
        } catch (error) {
            toast.error("Internal Server Error");
            console.log("Error");
            setProgress(100);
        }
    };


    const loginGuest = async () => {
        navigate("/login");
    };

    // Check if localCart is empty, then set isLoading to false
    if (localCart.length === 0 && !user.phone_number) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="text-center">
                    <h1 className="mb-4">Your Cart is Empty</h1>
                    <p className="lead">Looks like you haven't added any items to your cart yet.</p>
                </div>
            </div>
        );
    }

    if (cart.length === 0 && !!user.phone_number) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="text-center">
                    <h1 className="mb-4">Your Cart is Empty</h1>
                    <p className="lead">Looks like you haven't added any items to your cart yet.</p>
                </div>
            </div>
        );
    }

    const handleGuest = () => {
        setGuestModal(false);
        setShowingName(true)
    }

    return (
        <>
            <LoadingBar
                color="#f06c14"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />
            {isLoading ? (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : user.phone_number ? (
                <>
                    <div className="bg-white p-3 mb-3 shadow-sm">
                        <div>
                            {cart.map((val, key) => (
                                <>
                                    <div className="mb-3 d-flex gap-2">
                                        <img
                                            src={val.item_type === "Non Veg" ? nonveg : veg}
                                            alt=""
                                            className="img-fluid ch-20"
                                        />
                                        <div>
                                            <h6 className="fw-bold mb-1">{val.item_name}</h6>
                                            <p className="mb-0">
                                                {det.currency_code} {val.item_total_amount_with_addon}
                                            </p>
                                            {val.addons.length > 0 ? (
                                                <>
                                                    {val.addons.map((addonItem) => (
                                                        <p className="small text-muted m-0">
                                                            + {addonItem.addon_name}
                                                        </p>
                                                    ))}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div className="ms-auto text-end cw-80">
                                            <div className="btn btn-white btn-sm border border-danger px-2 rounded">
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <div className="minus">
                                                        <i
                                                            className="fa-solid fa-minus text-danger"
                                                            onClick={() =>
                                                                subtractFromFinalPopup(val)
                                                            }
                                                        ></i>
                                                    </div>
                                                    <input
                                                        className="shadow-none form-control text-center border-0 p-0 box"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label="default input example"
                                                        value={val.quantity}
                                                    />
                                                    <div className="plus">
                                                        <i
                                                            className="fa-solid fa-plus text-danger"
                                                            onClick={() => addFromFinalPopup(val)}
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            ))}
                        </div>
                        <div className="text-muted small">
                            <i className="fa-solid fa-layer-group me-2"></i>&nbsp;Do you want to add
                            cooking instructions?
                        </div>
                    </div>
                    <div className="bg-white p-3 mb-3 shadow-sm">
                        <a href="#coupon">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex gap-2 h5 m-0">
                                    <div>
                                        <i className="fa-solid fa-tags text-primary"></i>
                                    </div>
                                    {coupon.length > 0 ? (
                                        <>
                                            <div className="fw-bold text-muted">{coupon}</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="fw-bold text-muted">Use coupons</div>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <i className="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="bg-white p-3 mb-3 shadow-sm">
                        <div className="d-flex justify-content-between">
                            <div>Items Total</div>
                            <div>{det.currency_code} {cartTotals.cart_total_amount_with_addon}</div>
                        </div>
                        <div className="accordion">
                            <div className="accordion-item bg-white border-0">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                    <button
                                        className="accordion-button px-0 pt-3 pb-3 bg-white border-0 shadow-none h5 mb-0 fw-normal text-dark"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpen-collapseTwo"
                                    >
                                        Taxes & Charges
                                    </button>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseTwo"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="panelsStayOpen-headingTwo"
                                >
                                    <div className="accordion-body px-0 pt-0">
                                        {cartCharges.length > 0 ? (
                                            cartCharges.map((x) => (
                                                <div className="d-flex justify-content-between text-muted mb-2">
                                                    <div>{x.charges_name}</div>
                                                    <div>{det.currency_code} {x.charges_amount}</div>
                                                </div>
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                        <div className="d-flex justify-content-between text-muted mb-2">
                                            <div>Taxes</div>
                                            <div>{det.currency_code} {cartTotals.total_tax_amount}</div>
                                        </div>
                                        <div className="d-flex justify-content-between text-muted mb-2">
                                            <div>Restaurant Discount</div>
                                            <div>- {det.currency_code} {cartTotals.reward_discount_amount}</div>
                                        </div>
                                        {coupon.length > 0 ? (
                                            <>
                                                <div className="d-flex justify-content-between text-muted mb-2">
                                                    <div>
                                                        Coupon Discount - {cartTotals.coupon_code}
                                                    </div>
                                                    <div>
                                                        - {det.currency_code} {cartTotals.coupon_discount_amount}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between h4 fw-bold">
                            <div>Grand Total</div>
                            <div>{det.currency_code} {cartTotals.new_grand_total}</div>
                        </div>
                    </div>
                    {/* <div className="bg-white p-3 mb-5 pb-5">
                        <h5 className="fw-bold">Cancellation Policy</h5>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. In aliquam
                            labore temporibus, quo ea accusantium repellendus pariatur.?
                        </p>
                        <div className="small">Avoid cacellations as it leads to food wastage</div>
                    </div> */}
                    <div className="fixed-bottom p-3 mt-5">
                        <a onClick={() => placeOrder()} className="btn btn-danger btn-lg d-grid">
                            <div className="d-flex justify-content-between">
                                <div></div>
                                <div>Place Order</div>
                                <div>
                                    <i className="fa-solid fa-caret-right"></i>
                                </div>
                            </div>
                        </a>
                    </div>
                </>
            ) : (
                <>
                    {/* User is not present */}
                    {/* User is not present */}
                    {/* User is not present */}
                    {/* User is not present */}
                    {/* User is not present */}
                    <div className="bg-white p-3 mb-3 shadow-sm">
                        <div>
                            {cart.map((val, key) => (
                                <>
                                    <div className="mb-3 d-flex gap-2">
                                        <img
                                            src={val.item_type === "Non Veg" ? nonveg : veg}
                                            alt=""
                                            className="img-fluid ch-20"
                                        />
                                        <div>
                                            <h6 className="fw-bold mb-1">{val.item_name}</h6>
                                            <p className="mb-0">
                                                {det.currency_code} {val.item_total_amount_with_addon}
                                            </p>
                                            {val.addons.length > 0 ? (
                                                <>
                                                    {val.addons.map((addonItem) => (
                                                        <p className="small text-muted m-0">
                                                            + {addonItem.addon_name}
                                                        </p>
                                                    ))}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                        <div className="ms-auto text-end cw-80">
                                            <div className="btn btn-white btn-sm border border-danger px-2 rounded">
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <div className="minus">
                                                        <i
                                                            className="fa-solid fa-minus text-danger"
                                                            onClick={() =>
                                                                subtractFromFinalPopupLocal(val)
                                                            }
                                                        ></i>
                                                    </div>
                                                    <input
                                                        className="shadow-none form-control text-center border-0 p-0 box"
                                                        type="text"
                                                        placeholder=""
                                                        aria-label="default input example"
                                                        value={val.quantity}
                                                    />
                                                    <div className="plus">
                                                        <i
                                                            className="fa-solid fa-plus text-danger"
                                                            onClick={() =>
                                                                addFromFinalPopupLocal(val)
                                                            }
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </>
                            ))}
                        </div>
                        <div className="text-muted small">
                            <i className="fa-solid fa-layer-group me-2"></i>&nbsp;Do you want to add
                            cooking instructions?
                        </div>
                    </div>
                    <div className="bg-white p-3 mb-3 shadow-sm">
                        <a>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex gap-2 h5 m-0">
                                    <div>
                                        <i className="fa-solid fa-tags text-muted"></i>
                                    </div>
                                    <div className="fw-bold text-muted">Use coupons</div>
                                </div>
                                <div>
                                    <i className="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </a>

                        <div className="text-muted small mt-1">
                            Coupons only valid when you log in.
                        </div>
                    </div>
                    <div className="bg-white p-3 mb-3 shadow-sm">
                        <div className="d-flex justify-content-between">
                            <div>Items Total</div>
                            <div>{det.currency_code} {cartTotals.item_total_amount_with_addon}</div>
                        </div>
                        <div className="accordion">
                            <div className="accordion-item bg-white border-0">
                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                    <button
                                        className="accordion-button px-0 pt-3 pb-3 bg-white border-0 shadow-none h5 mb-0 fw-normal text-dark"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#panelsStayOpen-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="panelsStayOpen-collapseTwo"
                                    >
                                        Taxes & Charges
                                    </button>
                                </h2>
                                <div
                                    id="panelsStayOpen-collapseTwo"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="panelsStayOpen-headingTwo"
                                >
                                    <div className="accordion-body px-0 pt-0">
                                        {cartCharges.length > 0 ? (
                                            cartCharges.map((x) => (
                                                <div className="d-flex justify-content-between text-muted mb-2">
                                                    <div>{x.charges_name}</div>
                                                    <div>{det.currency_code} {x.charges_amount}</div>
                                                </div>
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                        <div className="d-flex justify-content-between text-muted mb-2">
                                            <div>Taxes</div>
                                            <div>{det.currency_code} {cartTotals.total_tax_amount}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between h4 fw-bold">
                            <div>Grand Total</div>
                            <div>{det.currency_code} {cartTotals.grand_total_with_addon}</div>
                        </div>
                    </div>
                    {/* <div className="bg-white p-3 mb-5 pb-5">
                        <h5 className="fw-bold">Cancellation Policy</h5>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. In aliquam
                            labore temporibus, quo ea accusantium repellendus pariatur.?
                        </p>
                        <div className="small">Avoid cacellation as it leads to food wastage</div>
                    </div> */}
                    <div className="fixed-bottom p-3 mt-5">
                        <a
                            onClick={handleOrder}
                            className="btn btn-danger btn-lg d-grid"
                        >
                            <div className="d-flex justify-content-between">
                                <div></div>
                                <div>Place Order</div>
                                <div>
                                    <i className="fa-solid fa-caret-right"></i>
                                </div>
                            </div>
                        </a>
                    </div>
                </>
            )}
            {/* <!-- LOCAL - Modal to add subtract items with addons already in cart --> */}
            <Modal size="md" show={guestModal} onHide={() => setGuestModal(false)}>
                <div className="d-flex justify-content-between border-0">
                    <div className="d-flex align-items-center ">
                        <div className="p-3 text-bold-custom">Login / Sign in</div>
                    </div>
                    <button
                        type="button"
                        className="btn-close p-3"
                        aria-label="Close"
                        onClick={() => setGuestModal(false)}
                    ></button>
                </div>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">

                        <div className="bg-white p-3 mb-3">
                            <div>
                                To Avail Loyalty Discounts, Exculusive Discounts & Coupons, Please
                                Login.
                            </div>
                        </div>

                        <div className=" d-flex justify-content-around">
                            <btn
                                className="btn btn-small-text-custom btn-outline-danger col-5"
                                onClick={handleGuest}
                            >
                                Continue as guest
                            </btn>
                            <btn
                                className="btn btn-small-text-custom btn-danger col-5"
                                onClick={() => loginGuest()}
                            >
                                Login / Signup
                            </btn>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal size="md" show={showingName} onHide={() => setShowingName(false)}>
                <div className="d-flex justify-content-end border-0">
                    <button
                        type="button"
                        className="btn-close p-3"
                        aria-label="Close"
                        onClick={() => setShowingName(false)}
                    ></button>
                </div>
                <div className="modal-dialog">
                    <div className="modal-content border-0">
                        <div className="bg-white mb-3">
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                <label htmlFor="name" className="form-label">Enter Your Name</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Name"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ width: '100%' }}
                                />
                                <div className="d-flex justify-content-around mt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-small-text-custom btn-outline-danger col-5"
                                        style={{ width: '100%' }}
                                    >
                                        Place Order
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateCart: (cart) => dispatch(updateCart(cart)),
        updateCartTotals: (cart) => dispatch(updateCartTotals(cart)),
        updateCartCharges: (cart) => dispatch(updateCartCharges(cart)),
        updateCartCoupons: (cart) => dispatch(updateCartCoupons(cart)),
        updateLocalCart: (cart) => dispatch(updateLocalCart(cart)),
        updateHistory: (cart) => dispatch(updateHistory(cart)),
    };
};

const mapStateToProps = (state) => {
    return {
        cart: state.shop.cart,
        localCart: state.shop.localCart,
        cartTotals: state.shop.cartTotals,
        cartCoupons: state.shop.cartCoupons,
        coupon: state.shop.coupon,
        cartCharges: state.shop.cartCharges,
        details: state.shop.details,
        user: state.shop.user,
        table: state.shop.table,
        rest: state.shop.rest,
        url: state.shop.url,
        token: state.shop.token,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartDetail);
